const odooDecription1: string =
  " est un système de planification des ressources entièrement intégré et personnalisable, un logiciel de gestion d'entreprise open source. Odoo intègre la gestion de la relation client (CRM), les ventes, la gestion de projets, la fabrication, la gestion des stocks, la comptabilité, la gestion des ressources humaines, les activités de marketing, les outils de support client et d'autres applications commerciales en une seule solution logicielle.";
const odooDescription2: string =
  "Odoo est une suite d'applications commerciales open source qui répond aux besoins des entreprises de toutes tailles et de tous budgets dans tous les secteurs d'activité. Odoo dispose d'une application commerciale pour pratiquement tous les besoins commerciaux.";

const glpiDescription1: string =
  "CloudApps Solutions met à votre disposition une offre à la demande (SaaS) vous permettant de disposer rapidement (en quelques clics) d'une instance GLPI personnalisée fonction de vos besoins.";
const glpiDescription2: string =
  "Cette Instance GLPI est hébergée chez un cloud provider public et bénéficie de ce fait de tous les avantages du cloud. Elle est configurée et maintenue par nos soins de façon à garantir sa haute disponibilité et à respecter les normes et bonnes pratiques en matière de sécurité dans le cloud.";

export const AppConstant = {
  odooDecription1,
  odooDescription2,
  glpiDescription1,
  glpiDescription2,
};

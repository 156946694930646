// src/pages/Footer.tsx
import React from "react";
import { Images } from "../constant";
import { routes } from "../types/model/route";


const Footer: React.FC = () => {
    return (
        <div className="min-h-64  h-full w-screen bg-gray-900">
            <div className=" h-full w-full flex flex-col">
                <div className="w-full min-h-48 h-3/4  bg-primary-500 flex flex-col md:flex-row justify-between items-center">
                    <div className="container w-full h-full px-4 xl:px-16 2xl:px-0 mx-auto gap-4 lg:gap-auto  py-4 flex flex-col md:flex-row justify-between items-center">
                        {/* Logo */}
                        <div className="flex-shrink-0">
                            <a href="/">
                                <img src={Images.logoFooter} alt="Logo" className=" w-28 xl:w-36 mb-4 md:mb-auto" />
                            </a>
                        </div>

                        {/* Links */}

                        <div className="  flex flex-col md:flex-row items-center  justify-start gap-8">
                            {routes.map((route) =>
                            (

                                <a
                                    key={route.name}
                                    href={route.path}
                                    className="text-gray-200 hover:text-white hover:font-semibold"
                                >
                                    {route.name}
                                </a>

                            )
                            )}


                        </div>

                        <a href="tel:+xxxxxxxxx" className="text-gray-100">Nous appeler : <span className="font-medium ">07 xx xx xx xx</span></a>


                    </div>
                </div>


                <div className="w-full h-1/4 px-8 py-4   text-gray-200 flex justify-center items-center">
                    <div className="flex items-center">
                        <div className="text-center">
                            2024 - CloudApps. All rights reserved -{" "}
                            <a
                                href="/"
                                target="_blank"
                                className="transition-all duration-300 text-white underline underline-offset-2"
                            >
                                Mentions légale
                            </a>{" "}
                            -{" "}
                            <a
                                href="/"
                                target="_blank"
                                className="transition-all duration-300 text-white underline underline-offset-2"
                            >
                                Politique de confidentialité
                            </a>{" "}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;

import React, { ReactNode } from 'react'
import clsx from 'clsx'


interface HProps {
    classNames: string;
    Component: ReactNode | string;
}

const H1 = ({ classNames, Component }: HProps) => {
    return (
        <h1 className={clsx("font-semibold text-4xl leading-10 xl:mt-16 xl:text-[60px] xl:leading-[70px] 2xl:leading-[80px] 2xl:text-[70px] text-start", classNames)}>{Component}</h1>
    )
}



const H2 = ({ classNames, Component }: HProps) => {
    return (
        <h2 className={clsx("text-3xl lg:text-6xl font-semibold", classNames)}>{Component}</h2>
    )
}

const H3 = ({ classNames, Component }: HProps) => {
    return (
        <h1 className={clsx("text-3xl lg:text-4xl font-semibold flex flex-col gap-4", classNames)}>

            {Component}
        </h1>
    )
}

export { H1, H2, H3 }
import list_employe from '../../../types/constants/list_employe'
import { H2 } from '../../commons/h_component';
import ImageMotion from '../../commons/wrappers/imageMotion';
import { EmployeComponent } from './employe_component';

export default function ListEmployeComponent() {
    const employes = [...list_employe];

    return (
        <section id='section-employe' className=' relative w-full h-full flex items-center justify-center  pt-4 md:py-0  md:mt-14  '>
            <div className='container w-full h-full px-4 lg:px-16 2xl:px-0  gap-14 lg:gap-24  flex flex-col  items-center justify-center'>
                <ImageMotion classNames='' Component={
                    <H2 classNames="" Component={"Notre Equipe"} />
                } />
                <div className='container relative w-full h-full grid grid-cols-1  lg:grid-cols-2  gap-y-4 md:gap-y-16 lg:gap-x-16 2xl:gap-x-36'>
                    {employes.map(employe => (
                        <EmployeComponent key={employe.name} employe={employe} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export interface FormDataState {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  taxNumber: string;
  phoneNumber: string;
  methodOfPayment: string;
}

export const initialFormData: FormDataState = {
  firstName: "",
  lastName: "",
  company: "",
  email: "",
  address: "Littoral-cameroun",
  postalCode: "",
  city: "",
  country: "Cameroun",
  taxNumber: "",
  phoneNumber: "",
  methodOfPayment:""
};
  
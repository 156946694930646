import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initialLocation,
  maintenanceTypes,
  maxAssets,
} from "../../../constant/constant";
import { AppDispatch, RootState } from "../../../store";
import {
  cleanMaintenanceType,
  cleanProduct,
  setHostingType,
  setIsAnnual,
  setMaintenance,
  setMaintenanceType,
  setNumberOfEmployees,
  setNumUsers,
  setSelectedProducts,
  setSupportImplementation,
  setTypePlan,
  setWebsite,
} from "../../../store/odooSubscriptionSlice";
import { styles } from "../../../styles";
import { listProductsModal } from "../../../types/constants/products/modal_product";
import { H2 } from "../../commons/h_component";
import InputNumber from "../../input_number_component";
import ModalSubscription from "../../modal/modalSubscriptionOdoo";
import Modal from "../../modal/modal_subscribe";
import ModalFormOdooLocal from "./modal_odoo_conf_local_form";
import { PricingTable } from "./price_card_odoo";
import ServiceQuestions from "./service_component";

interface SubscriptionFormProps {
  onNext: ({ city }: { city: string }) => void;
}

const SubscriptionForm: React.FC<SubscriptionFormProps> = ({ onNext }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [modalConfigLocal, setModalConfigLocal] = useState<boolean>(false);

  const [modal, setModal] = useState<boolean>(false);

  const {
    numUsers,
    hostingType,
    typePlan,
    isAnnual,
    website,
    numberOfEmployees,
    selectedProducts,
    maintenance,
    location,
    typeMaintenance,
    supportImplementation,
  } = useSelector((state: RootState) => state.odooSubscription);

  const [disableCheck, setDisableCheck] = useState<boolean>(
    numberOfEmployees > 20
  );

  useEffect(() => {
    let typePlan = "starter";
    if ((numUsers > 5 || website) && numUsers < 20) {
      typePlan = "standard";
    } else if (numUsers > 19) {
      typePlan = "Premium";
    }
    dispatch(setTypePlan(typePlan));
  }, [numUsers, maintenance, website, dispatch]);

  const handleNumUsersChange = (value: number) => {
    dispatch(setNumUsers(value));
  };

  const handleIsAnnualChange = (newChecked: boolean) => {
    dispatch(setIsAnnual(newChecked));
  };

  const handleWebsiteChange = (website: boolean) => {
    dispatch(setWebsite(website));
  };

  const handleMaintenanceTypeChange = (newChecked: string) => {
    dispatch(setMaintenanceType(newChecked));
  };

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    dispatch(setSelectedProducts({ value, checked }));
  };

  const handleMaintenanceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    dispatch(setMaintenance(value === "yes"));
    if (value !== "yes") {
      dispatch(cleanMaintenanceType());
    }
  };

  const handleSupportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch(setSupportImplementation(value === "yes"));
    if (value !== "yes") {
      dispatch(cleanProduct());
    }
  };

  const toggleModalLocal = useCallback(() => {
    setModalConfigLocal((prev) => !prev);
  }, []);

  const toggleModal2 = useCallback(() => {
    setModal((prev) => !prev);
  }, []);
  const setSupportImplementationCallback = useCallback(
    (newChecked: boolean) => {
      dispatch(setSupportImplementation(newChecked));
    },
    [dispatch]
  );

  useEffect(() => {
    if (numberOfEmployees > 20) {
      setSupportImplementationCallback(true);
      setDisableCheck(true);
      // setDisableCheck should be handled here or in a similar manner
    } else {
      setDisableCheck(false); //handle this state if required
    }
  }, [numberOfEmployees, setSupportImplementationCallback]);

  const handleNextClick = () => {
    if (numberOfEmployees === 0 || numUsers === 0) {
      alert("Veuillez remplir tous les champs svp !!");
    } else {
      onNext({ city: location ?? initialLocation });
    }
  };

  const handleEmployeesChange = useCallback(
    (value: number) => {
      dispatch(setNumberOfEmployees(value < maxAssets ? value : maxAssets));
    },
    [dispatch]
  );

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-full flex flex-col gap-24 items-center justify-center">
        <H2
          classNames={clsx(
            styles.serviceStyle["title"],
            "text-center md:text-start"
          )}
          Component={
            <p>
              Odoo{" "}
              <span
                className={clsx(styles.serviceStyle["title-pro"], "capitalize")}
              >
                {hostingType === "cloud" ? typePlan : hostingType}
              </span>
            </p>
          }
        />

        <div className="w-full h-full flex flex-col lg:flex-row  xl:gap-16">
          <div className="w-full lg:w-7/12 h-full flex flex-col gap-8 text-md xl:text-lg leading-8">
            <div className="w-full flex flex-col">
              <InputNumber
                name="userNumber"
                title=" Nombre d'utilisateurs"
                setNumber={handleNumUsersChange}
                error={numUsers === 0}
                numberOfItems={numUsers}
              />

              <InputNumber
                name="numberOfEmployees"
                title="Nombre d'employes"
                setNumber={handleEmployeesChange}
                error={numberOfEmployees === 0}
                numberOfItems={numberOfEmployees}
              />
            </div>
            <ServiceQuestions onServiceChange={handleWebsiteChange} />

            <div className="mb-4  flex flex-col ">
              <h3 className="text-xl font-semibold mb-4 xl:mb-8">
                Type d'hébergement:
              </h3>
              <div>
                <label className="block mb-2 ">
                  <input
                    type="radio"
                    name="hostingType"
                    value="on-premises"
                    checked={hostingType === "on-premises"}
                    onChange={(e) => dispatch(setHostingType(e.target.value))}
                    className="mr-2"
                  />
                  On-premises
                </label>
                <div
                  className={clsx(
                    "bg-blue-100 mb-2 ml-8 md:w-4/5 rounded-lg flex flex-col gap-4 p-4 text-md leading-6 text-justify",
                    "transition transition-all duration-300 ease-in-out",
                    {
                      hidden: hostingType !== "on-premises",
                    }
                  )}
                >
                  <p>Optez pour un hébergement local de votre solution Odoo.</p>

                  <div className="w-full flex justify-end text-white">
                    <button
                      onClick={toggleModalLocal}
                      className="px-8 py-4 bg-primary-500 rounded"
                    >
                      <FontAwesomeIcon icon={faCog} className="size-5 mr-2" />
                      <span>Configurer</span>
                    </button>
                    <Modal
                      isOpen={modalConfigLocal}
                      toggleModal={toggleModalLocal}
                      title="Estimateur de projet"
                    >
                      {" "}
                      <ModalFormOdooLocal onClose={toggleModalLocal} />
                    </Modal>
                  </div>
                </div>
              </div>
              <div className="w-full relative">
                <label className="block mb-2">
                  <input
                    type="radio"
                    name="hostingType"
                    value="cloud"
                    checked={hostingType === "cloud"}
                    onChange={(e) => dispatch(setHostingType(e.target.value))}
                    className="mr-2"
                  />
                  Cloud optimisé et securisé de CloudApps
                </label>
                <div
                  className={clsx(
                    "bg-blue-100 ml-8  md:w-4/5 rounded-lg flex flex-col gap-4 p-4 text-md leading-6 text-justify",
                    "transition transition-all duration-300 ease-in-out",
                    {
                      hidden: hostingType !== "cloud",
                    }
                  )}
                >
                  <p>
                    Profitez des atouts du cloud optimiser et sécuriser de
                    CloudApps. Grâce à l'hébergement cloud, bénéficiez d'une
                    plateforme ultra-performante, hautement disponible et dotée
                    de fonctions de sécurité de pointe. Concentrez-vous sur
                    votre cœur de métier.
                  </p>
                </div>
              </div>
            </div>

            {/* Service de mise en œuvre */}
            <div className="mb-4">
              <h3 className="text-xl font-semibold mb-4">
                Support d'accompagnement à la mise en œuvre:
              </h3>
              <p className="text-gray-800 mb-4">
                Veuillez sélectionner les applications Odoo pour lesquelles vous
                souhaitez bénéficier d'un accompagnement.
              </p>
              <div>
                {" "}
                <label className="block mb-2">
                  <input
                    type="radio"
                    name="supportImplementation"
                    value="yes"
                    checked={supportImplementation}
                    onChange={handleSupportChange}
                    className="mr-2"
                  />
                  Oui
                </label>
                <div
                  className={clsx(
                    "bg-blue-100 pt-4 mb-2 ml-4  rounded-lg flex flex-col gap-4 p-4 text-md leading-6 text-justify",
                    "transition transition-all duration-300 ease-in-out",
                    {
                      hidden: !supportImplementation,
                    }
                  )}
                >
                  <div className="w-full grid md:grid-cols-2  gap-x-8">
                    {listProductsModal.map((product) => (
                      <div
                        key={product.name}
                        className="block my-2 flex items-start"
                      >
                        <input
                          type="checkbox"
                          id={product.name}
                          value={product.name}
                          checked={selectedProducts.includes(product.name)}
                          onChange={handleProductChange}
                          className="h-5 w-5 min-w-5 min-h-5 mt-1 text-indigo-600 border-gray-300 rounded"
                        />
                        <label
                          htmlFor={product.name}
                          className="ml-4 block text-gray-800"
                        >
                          <b className="font-bold">{product.name}</b>
                          <div className="flex text-sm text-gray-700 w-full text-start break-words ">
                            {product.products.join(", ")}
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <label className="block">
                <input
                  type="radio"
                  name="supportImplementation"
                  value="no"
                  disabled={disableCheck}
                  checked={!supportImplementation}
                  onChange={handleSupportChange}
                  className="mr-2"
                />
                Non
              </label>
            </div>
            <div className="py-4">
              <h3 className="font-semibold text-xl mb-4">
                Service de maintenance
              </h3>
              {/* <p className="text-gray-800">
                    Assurez la continuité et la performance de vos opérations avec notre service de maintenance proactive. Nos experts garantissent un support rapide et efficace pour éviter tout temps d'arrêt imprévu.
                </p> */}
              <div>
                <div className="flex items-center mt-4">
                  <input
                    type="radio"
                    id="maintenanceYes"
                    name="maintenance"
                    value="yes"
                    onChange={handleMaintenanceChange}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="maintenanceYes"
                    className="ml-3 block text-gray-700"
                  >
                    Oui
                  </label>
                </div>
                <div
                  className={clsx(
                    " ",
                    "pt-4  mb-2 ml-4  rounded-lg flex flex-col gap-4 px-4 text-md leading-6 text-justify",
                    "transition transition-all duration-300 ease-in-out",
                    {
                      hidden: !maintenance,
                    }
                  )}
                >
                  <span>
                    (*) Un ticket est un problème ou une demande formulée par le
                    client, qui devra être traité par cloudApps et par la suite
                    validé par le client avant d'être considéré comme résolu.
                  </span>
                  <div className="w-full grid grid-cols-1 gap-x-8">
                    {maintenanceTypes.map((product) => (
                      <div
                        key={product.type}
                        className="block my-2 flex items-start"
                      >
                        <input
                          type="checkbox"
                          id={product.type}
                          // value={product.value}
                          checked={typeMaintenance === product.type}
                          onChange={(e) =>
                            handleMaintenanceTypeChange(product.type)
                          }
                          className="h-5 w-5 min-w-5 min-h-5 mt-1 text-indigo-600 border-gray-300 rounded"
                        />
                        <label
                          htmlFor={product.type}
                          className="ml-4 block text-gray-800"
                        >
                          <span className="">
                            {product.value}{" "}
                            <span className="text-base font-medium capitalise">
                              ({product.price} FcFa)
                            </span>
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex items-center mt-2">
                <input
                  type="radio"
                  id="maintenanceNo"
                  name="maintenance"
                  value="no"
                  defaultChecked
                  onChange={handleMaintenanceChange}
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
                <label
                  htmlFor="maintenanceNo"
                  className="ml-3 block text-gray-700"
                >
                  Pas Interesser pour le moment
                </label>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-5/12 h-full flex flex-col pt-16 md:pt-0 items-center gap-4 ">
            <ToggleButton
              initialChecked={isAnnual}
              onCheckedChange={handleIsAnnualChange}
            />
            <div className="w-full">
              <PricingTable />
              <div className="w-full gap-8 flex xl:pt-8 flex-col xl:flex-row">
                <div className="w-full mx-auto flex items-center justify-center">
                  <button
                    onClick={(e) => setModal(true)}
                    className="h-14 w-full shadow bg-blue-500 py-2 text-lg transition ease-in duration-300 hover:ease-out hover:bg-blue-800  focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-lg"
                  >
                    Voir detail
                  </button>

                  <ModalSubscription
                    isOpen={modal}
                    toggleModal={toggleModal2}
                  />
                </div>
                <div className="w-full xl:flex-grow mx-auto  flex items-center justify-center">
                  <button
                    onClick={handleNextClick}
                    className="h-14 w-full shadow bg-primary-500 py-2 text-lg transition ease-in duration-300 hover:ease-out hover:bg-blue-500 hover:md:scale-y-110 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-lg"
                  >
                    Acheter Maintenant
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface ToggleButtonProps {
  initialChecked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  initialChecked,
  onCheckedChange,
}) => {
  const [checked, setChecked] = useState<boolean>(initialChecked);

  const toggleChecked = useCallback(() => {
    setChecked((prevChecked) => {
      const newChecked = !prevChecked;
      onCheckedChange(newChecked);
      return newChecked;
    });
  }, [onCheckedChange]);

  const checkedClassName = useMemo(
    () => clsx("ml-3", { "text-gray-900": checked }),
    [checked]
  );

  const uncheckedClassName = useMemo(
    () => clsx("mr-3", { "text-gray-800": checked }),
    [checked]
  );

  useEffect(() => {
    onCheckedChange(checked);
  }, [checked, onCheckedChange]);

  return (
    <div className="flex items-center text-xl leading-10 font-semibold text-gray-700">
      <span className={uncheckedClassName}>Mensuel</span>
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only peer"
          checked={checked}
          onChange={toggleChecked}
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
      </label>
      <span className={checkedClassName}>Annuel</span>
    </div>
  );
};

export default SubscriptionForm;


export interface ProductModal {
    index: number;
    name: string;
    products: string[]
}



export const listProductsGlpi:string[]=[
    "CMDB","Helpdesk","Gestion Financiere","Gestion de projet","Administration","Configuration",

]

export const listProductsModal: ProductModal[] = [
    {
        index: 1,
        name: "Ventes & CRM",
        products: ["Ventes", "Facturation", "Abonnements", "Location", "CRM"]

    },
    {
        index: 2,
        name: "Site Web",
        products: ["Site Web", "eCommerce", "Forum"]

    }, {
        index: 3,
        name: "Ressources humaines",
        products: ["Recrutement", "Congé", "Évaluations", "Notes de frais"]

    }, {
        index: 4,
        name: "Finance",
        products: ["Dépenses", "Consolidation comptable"]

    }, {
        index: 5,
        name: "Logistique",
        products: ["Approvisionnements", "Inventaire"]

    }, {
        index: 6,
        name: "Point de Vente",
        products: ["Boutique", "Restaurants"]

    },
]


import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUrlServer } from "../../constant/functions";
import { AppDispatch, RootState } from "../../store";
import { cleanUrlServer } from "../../store/odooSubscriptionSlice";
import { FormDataState } from "../../types/model/form_data_model";
import Modal from "../modal/modal_subscribe";
import ModalUrlConfForm from "./odoo/modal_url_form";

interface FormSubscriptionProps {
  onNext: any;
  onReset: any;
  onPrevious: any;
  formData: FormDataState;
  isGlpiForm: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormSubscription: React.FC<FormSubscriptionProps> = ({
  onNext,
  onReset,
  onPrevious,
  formData,
  onChange,
  isGlpiForm,
}) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onNext(formData);
  };
  const [modalConfigCloud, setModalConfigCloud] = useState<boolean>(false);

  const toggleModalLocal = useCallback(() => {
    setModalConfigCloud((prev) => !prev);
  }, []);

  const dispatch = useDispatch<AppDispatch>();

  const { urlServer } = useSelector(
    (state: RootState) => state.odooSubscription
  );

  const url = useMemo(
    () =>
      urlServer ??
      getUrlServer({ company: formData.company }),
    [formData.company, urlServer]
  );

  const [inputUrl, setInputUrl] = useState<string>(url);

  useEffect(() => {
    if (!urlServer) {
      setInputUrl(url);
    } else {
      setInputUrl(urlServer);
    }
  }, [url, urlServer]);

  const handleReset = (e: any) => {
    dispatch(cleanUrlServer());
    onReset(e);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="container flex flex-col gap-0 lg:gap-6 w-full"
    >
      <button
        onClick={onPrevious}
        className="w-36 text-gray-700 pb-4 font-bold text-start underline underline-offset-4"
      >
        Précédent
      </button>
      <div className="flex flex-wrap -mx-3  transition duration-300 ease-in-out">
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="firstName"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Prénom
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={onChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white "
            required
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="lastName"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Nom de famille
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={onChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            required
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 transition duration-300 ease-in-out">
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="company"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Entreprise
          </label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={onChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            required
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="email"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            E-mail
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={onChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            required
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3  transition duration-300 ease-in-out">
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="postalCode"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Code Postal
          </label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={formData.postalCode}
            onChange={onChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white "
            required
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="address"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Adresse
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={onChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            required
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3  transition duration-300 ease-in-out">
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="taxNumber"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Numéro de Taxe/TVA
          </label>
          <input
            type="text"
            id="taxNumber"
            name="taxNumber"
            value={formData.taxNumber}
            onChange={onChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 lg:mb-0">
          <label
            htmlFor="phoneNumber"
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
          >
            Numéro de téléphone
          </label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={onChange}
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            required
          />
        </div>
      </div>

      {!isGlpiForm && (<div className="w-full  mb-6 lg:mb-0">
        <label
          htmlFor="urlServer"
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-3"
        >
          Url de votre instance {isGlpiForm ? "GLPI" : "odoo"}
        </label>
        <div className="w-full flex flex-row md:h-12 gap-2 md:gap-8">
          <input
            type="text"
            id="urlServer"
            name="urlServer"
            value={inputUrl}
            disabled
            className="appearance-none block w-full h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          // required
          />
          <button
            type="button"
            onClick={toggleModalLocal}
            className="text-white h-12 flex flex-row px-4 sm:px-8 items-center justify-center bg-primary-500 rounded"
          >
            <FontAwesomeIcon icon={faCog} className="size-5 sm:mr-2" />
            <span className="hidden sm:block">Configurer</span>
          </button>
          <Modal
            isOpen={modalConfigCloud}
            toggleModal={toggleModalLocal}
            title={`Configuration de l'url de votre instance odoo`}
          >
            <ModalUrlConfForm
              onClose={toggleModalLocal}
              company={formData.company}
              isGlpi={isGlpiForm}
            />
          </Modal>
        </div>
      </div>)}
      <div className="w-full text-md  md:text-lg  relative  pt-6 flex gap-6  items-center justify-between">
        <button
          onClick={handleReset}
          className="h-14 w-36  shadow bg-gray-500  sm:py-2  focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-lg"
        >
          Reset
        </button>
        <button
          type="submit"
          className="h-14  w-48 shadow bg-primary-500  sm:py-2   transition ease-in duration-300 hover:ease-out hover:bg-blue-500  focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-lg"
        >
          Suivant
        </button>
      </div>
    </form>
  );
};

export default FormSubscription;

import { MaintenanceProp } from "../types/model/maintenance_prop";
import {
  initialLocation,
  maintenanceTypes,
  monthlySubscription,
  transportAnotherRegion,
  transportDouala,
  websitePrice,
} from "./constant";

interface TotalProp {
  selectedProducts: string[];
  supportImplementation: boolean;
  maintenance: boolean;
  typeMaintenance: string | null;
  typePlan: string;
  hostingType: string;
  isAnnual: boolean;
  tarifs: number;
  nDays: number | undefined;
  city: string | undefined;
}

interface OdooTotalProp extends TotalProp {
  numberOfEmployees: number;
  website: boolean;

}

interface GlpiTotalProp extends TotalProp {
  numberOfAssets: number;
}

export const getUrlServer = ({
  company,
}: {
  company: string;
}): string => {
  // Normaliser les caractères accentués et les décomposer
  const normalizedCompany = company.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  
  // Remplacer les caractères spéciaux par des tirets
  const sanitizedCompany = normalizedCompany
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "-");
  
  return sanitizedCompany
    ? `${sanitizedCompany}-odoo-run.ic-cloudapps.com`
    : "";
};


export const normaliseUrl = (company: string): string => {
  // Normaliser les caractères accentués et les décomposer
  const normalizedCompany = company.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  
  // Remplacer les caractères spéciaux par des tirets, sauf '.' et '/'
  const sanitizedCompany = normalizedCompany
    .toLowerCase()
    .replace(/[^a-z0-9./]/g, "-");
  
  return sanitizedCompany;
};


export const isValidUrl = (url: string): boolean => {
  const urlPattern = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}(\/.*)?$/i;
  return urlPattern.test(url);
};


export const calculateTotalCost = ({
  selectedProducts,
  supportImplementation,
  maintenance,
  typeMaintenance,
  typePlan,
  hostingType,
  isAnnual,
  website,
  tarifs,
  nDays,
  city,
}: OdooTotalProp): number => {
  //local installation
  let costInstallation = 0;

  if (hostingType !== "cloud") {
    costInstallation =
      calculateCostDepla(city ?? "Douala", nDays ?? 3) + tarifs * (nDays ?? 3);
  }

  //website price

  let costWebsite = 0;
  if (website) {
    costWebsite = websitePrice;
  }

  const costPlan = calculatePlanCost(typePlan);

  let maintenanceCost = 0;
  let supportCost = 0;

  if (supportImplementation) {
    supportCost = calculateMiseOeuvre(selectedProducts);
  }

  maintenanceCost = calculateMaintenanceCost(typeMaintenance);

  let totalCost = 0;

  if (isAnnual) {
    if (hostingType === "cloud") {
      totalCost =
        (maintenanceCost + costPlan) * monthlySubscription +
        costWebsite +
        costInstallation +
        supportCost;
    } else {
      totalCost =
        maintenanceCost * monthlySubscription +
        costWebsite +
        costInstallation +
        supportCost;
    }
  } else {
    totalCost =
      maintenanceCost + supportCost + costInstallation + costPlan + costWebsite;
  }

  return totalCost;
};

export const calculateAbonnementCost = ({
  maintenance,
  typeMaintenance,
  typePlan,
  hostingType,
  isAnnual,
}: {
  maintenance: boolean;
  typeMaintenance: string | null;
  typePlan: string;
  hostingType: string;
  isAnnual: boolean;
}): number => {
  let costPlanSubscription = 0;

  if (hostingType === "cloud") {
    costPlanSubscription =calculatePlanCost(typePlan);
  }

  const costPlan = costPlanSubscription;

  let maintenanceCost = 0;

  if (typeMaintenance !== null) {
    if (maintenance) {
      maintenanceCost = maintenanceTypes.filter(
        (value) => value.type === typeMaintenance
      )[0].price;
    }
  }
  return isAnnual
    ? (costPlan + maintenanceCost) * monthlySubscription
    : costPlan + maintenanceCost;
};

export const calculateInstallationCost = (
  accommodationCost: number,
  tarifs: number,
  nDays: number
): number => {
  return accommodationCost + tarifs * nDays;
};

export const calculateNumberDays = (numberOfEmployees: number): number => {
  let nDays = 0;

  if (numberOfEmployees < 30) {
    nDays = 3;
  } else if (numberOfEmployees < 60) {
    nDays = 6;
  } else {
    nDays = 6 + Math.ceil((numberOfEmployees - 59) / 50) * 3;
  }

  return nDays;
};

export const calculateCostDepla = (city: string, nDays: number): number => {
  let moveCost = transportDouala;

  let accommodationCost = 25000;
  let cost = 0;

  if (city === initialLocation) {
    cost = moveCost * nDays;
  } else {
    moveCost = transportAnotherRegion;
    cost = moveCost * 2 + 3000 * nDays + accommodationCost * nDays;
  }
  return cost;
};

export const calculateEmployeCost = (numberOfEmployees: number): number => {
  let employeeCost = 0;
  if (numberOfEmployees < 20) {
    employeeCost = 200000;
  } else if (numberOfEmployees < 50) {
    employeeCost = 400000;
  } else {
    employeeCost = 400000 + Math.ceil((numberOfEmployees - 49) / 40) * 200000;
  }

  return employeeCost;
};

export const calculateMaintenanceCost = (
  typeMaintenance: string | null
): number => {
  if (typeMaintenance === null) {
    return 0;
  }
  const selectedMaintenance = maintenanceTypes.find(
    (value) => value.type === typeMaintenance
  );
  return selectedMaintenance ? selectedMaintenance.price : 0;
};

export const calculatePlanCost = (typePlan: string): number => {
  if (typePlan === "starter") {
    return 35000;
  } else if (typePlan === "standard") {
    return 55000;
  }
  return 85000;
};

export const calculateMiseOeuvre = (selectedProducts: string[]): number => {
  let productCost = 0;
  const productCount = selectedProducts.length;
  if (productCount === 0) {
    productCost=0
  } else if (productCount < 3) {
    productCost = 100000;
  } else if (productCount < 5) {
    productCost = 200000;
  } else {
    productCost = 300000;
  }
  return productCost;
};

export const getMaintenanceByType = (
  type: string | null
): MaintenanceProp | null => {
  return type === null
    ? null
    : maintenanceTypes.filter((mn) => mn.type === type)[0];
};

//GLPI

export const calculateTotalGlpiCost = ({
  numberOfAssets,
  selectedProducts,
  supportImplementation,
  maintenance,
  typeMaintenance,
  typePlan,
  hostingType,
  isAnnual,
  tarifs,
  nDays,
  city,
}: GlpiTotalProp): number => {
  //local installation
  const costInstallation =
    hostingType === "cloud"
      ? 0
      : calculateCostDepla(city ?? "Douala", nDays ?? 3) +
      tarifs * (nDays ?? 3);



  const costPlan = calculatePlanCost(typePlan);

  let maintenanceCost = 0;
  let supportCost = 0;

  if (supportImplementation) {
    supportCost = calculateMiseOeuvre(selectedProducts);
  }

  if (typeMaintenance !== null) {
    if (maintenance) {
      maintenanceCost = maintenanceTypes.filter(
        (value) => value.type === typeMaintenance
      )[0].price;
    }
  }

  let totalCost = 0;

  if (isAnnual) {
    if (hostingType === "cloud") {
      totalCost =
        (maintenanceCost + costPlan) * monthlySubscription +
        supportCost;
    } else {
      totalCost =
        maintenanceCost * monthlySubscription +
        costInstallation +
        supportCost;
    }
  } else {
    totalCost =
      maintenanceCost + supportCost + costInstallation + costPlan;
  }

  return totalCost;
};

export const calculateAssetsInstallationDays = (
  numberOfAssets: number
): number => {
  let nDays = 3;

  if (numberOfAssets < 50) {
    nDays = 3;
  } else if (numberOfAssets < 100) {
    nDays = 6;
  } else {
    nDays = 6 + Math.ceil((numberOfAssets - 99) / 50) * 3;
  }

  return nDays;
};

export const calculateAssetsCost = (numberOfAssets: number): number => {
  let assetsCost = 0;
  if (numberOfAssets < 20) {
    assetsCost = 200000;
  } else if (numberOfAssets < 50) {
    assetsCost = 400000;
  } else {
    assetsCost = 400000 + Math.ceil((numberOfAssets - 49) / 40) * 200000;
  }

  return assetsCost;
};

export const calculateAssetsInstallationCost = (
  accommodationCost: number,
  tarifs: number,
  nDays: number
): number => {
  return accommodationCost + tarifs * nDays;
};

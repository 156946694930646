export interface RouteCategory {
  title?: string;
  routes: RouteLink[];
}
export interface RouteLink {
  name: string;
  path: string;
  isPricing?: boolean;
  subLink?: RouteCategory[] | null;
}

export const routes: RouteLink[] = [
  { name: "Accueil", path: "/" },

  {
    name: "Produits",
    path: "/products",
    subLink: [
      {
        title: "OFFRES SAAS",

        routes: [
          { name: "CloudApps Odoo", path: "/products/sub1" },
          { name: "CloudApps GLPI", path: "/products/sub2" },
        ],
      },
      {
        title: "OFFRES PAAS",

        routes: [
          { name: "CloudApps GLPI", path: "/products/sub1" },
          { name: "Jenkins Server as Service", path: "/products/sub2" },
          { name: "AWX Server As Service", path: "/products/sub3" },
          { name: "​GitLab Servers As Service", path: "/products/sub4" },
        ],
      },
      {
        title: "CONSULTING",

        routes: [
          { name: "DevOps / DevSecOps", path: "/products/sub1" },
          { name: "Cloud/Architecture Design", path: "/products/sub2" },
          { name: "Cybersecurity", path: "/products/sub3" },
        ],
      },
    ],
  },
  {
    name: "Tarifs",
    path: "/ ",
    isPricing: true,
    subLink: [
      {
        routes: [
          { name: "CloudApps Odoo", path: "/subcribe-odoo" },
          { name: "CloudApps GLPI", path: "/subcribe-glpi" },
        ],
      },
    ],
  },
  { name: "Blog", path: "/blog" },
  // { name: "Shop", path: "/shop" },
  { name: "À propos de nous", path: "/" },
];

import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dailyCost, discountMonth, initialLocation, monthlySubscription } from "../../../constant/constant";
import { calculateAbonnementCost, calculateCostDepla, calculateInstallationCost, calculateMaintenanceCost, calculateMiseOeuvre, calculateNumberDays, calculateTotalCost, calculatePlanCost } from "../../../constant/functions";
import { AppDispatch, RootState } from "../../../store";
import { setTotalPrice } from "../../../store/odooSubscriptionSlice";



export const PricingTable = memo(
    () => {
        const {
            hostingType,
            typePlan,
            isAnnual,
            website,
            numberOfEmployees,
            selectedProducts,

            numUsers,
            maintenance,
            location,
            typeMaintenance,
            supportImplementation,
        } = useSelector((state: RootState) => state.odooSubscription);

        const nDays = useMemo(() => calculateNumberDays(numberOfEmployees), [numberOfEmployees])

        const pricePerMonth = useMemo(() => hostingType !== 'cloud' ? 0 : calculatePlanCost(typePlan), [typePlan, hostingType]);

        const maintenanceCost = useMemo(() => calculateMaintenanceCost(typeMaintenance), [typeMaintenance]);

        const installationCost = useMemo(() => hostingType === 'cloud' ? 0 : calculateInstallationCost(calculateCostDepla(location ?? initialLocation, nDays), dailyCost, nDays),
            [hostingType, location, nDays]);

        const totalPrice = useMemo(() => calculateTotalCost({
            numberOfEmployees: numberOfEmployees,
            selectedProducts: selectedProducts,
            supportImplementation: supportImplementation,
            maintenance: maintenance,
            typeMaintenance: typeMaintenance,
            typePlan: typePlan,
            hostingType: hostingType,
            isAnnual: isAnnual,
            website: website,
            tarifs: dailyCost,
            nDays: nDays,
            city: location
        }), [numberOfEmployees, selectedProducts, supportImplementation, maintenance,
            typeMaintenance, typePlan, hostingType, isAnnual,
            website, nDays, location]);

        const subscriptionCost = useMemo(() => calculateAbonnementCost({

            maintenance: maintenance,
            typeMaintenance: typeMaintenance,
            typePlan: typePlan,
            hostingType: hostingType,
            isAnnual: isAnnual,

        }), [maintenance,
            typeMaintenance, typePlan, hostingType, isAnnual]);

        const miseOeuvreCost = useMemo(() => calculateMiseOeuvre(selectedProducts), [selectedProducts])
        const dispatch = useDispatch<AppDispatch>();

        const handleChangeTotal = useCallback(
            () => {
                dispatch(setTotalPrice(totalPrice))
            },
            [totalPrice, dispatch],
        )

        useEffect(() => {
            handleChangeTotal()
        }, [totalPrice, handleChangeTotal])

        return (
            <div className="w-full py-8 flex flex-col">
                <div className="text-md xl:text-lg leading-8">
                    <div>
                        <span className="text-xl leading-10 font-semibold underline underline-offset-2">Abonnement :</span>
                        <ul className="pl-4 py-2 list-outside list-disc text-md">
                            <li className="w-full flex flex-wrap items-center mb-2">
                                <span className="flex-grow">
                                    Instance odoo {hostingType} ({numUsers} {numUsers > 1 ? "utilisateurs" : "utilisateur"})
                                </span>
                                <span className="font-medium ml-auto">
                                    <span className="uppercase">{pricePerMonth.toLocaleString()} FcFa</span>
                                    {(isAnnual && hostingType === "cloud") && ` x ${monthlySubscription} mois`}
                                </span>
                            </li>
                            {maintenance && (
                                <li className="w-full flex flex-wrap items-center mb-2">
                                    <span className="flex-grow">Support de maintenance</span>
                                    <span className="font-medium ml-auto">
                                        <span className="uppercase">{maintenanceCost} FcFa</span>
                                        {(isAnnual) && ` x ${monthlySubscription} mois`}
                                    </span>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div>
                        <span className="text-xl leading-10 font-semibold underline underline-offset-2">Forfait</span>
                        <span className="font-normal text-base"> (à payer une seule fois à la souscription) :</span>
                        <ul className="pl-4 py-2 list-outside space-y-4 list-disc text-md">
                            <li className="w-full flex flex-wrap items-center mb-2">
                                <span className="flex-grow">
                                    Mise en oeuvre <span className="text-base">(accompagnement à la configuration initiale)</span>
                                </span>
                                <span className="font-medium uppercase ml-auto">{miseOeuvreCost} FcFa</span>
                            </li>
                            <li className="w-full flex flex-wrap items-center mb-2">
                                <span className="flex-grow">Forfait d'installation</span>
                                <span className="font-medium uppercase ml-auto">{installationCost} FcFa</span>
                            </li>
                        </ul>
                    </div>
                    <div className="border-t-2 w-full py-4 border-gray-200 flex flex-row justify-between">
                        <span className="font-bold text-xl">Total</span>
                    </div>
                    <div className="w-full pl-4 flex-col gap-4 flex items-start justify-center">
                        <div className="w-full flex flex-wrap items-center">
                            <span className="flex-grow">Montant à payer pour les abonnements (Prix /{isAnnual ? "an" : "mois"}) :</span>
                            <span className="text-end uppercase font-bold ml-auto">{subscriptionCost.toLocaleString()} FcFa</span>
                        </div>
                        <div className="w-full flex flex-wrap items-center">
                            <span className="flex-grow">Montant à payer maintenant :</span>
                            <span className="uppercase font-bold ml-auto">{totalPrice.toLocaleString()} FcFa</span>
                        </div>
                    </div>
                    {isAnnual && (
                        <div className=" pl-4 w-full text-md py-4 flex flex-wrap justify-between">
                            <span className="">(*) Remise initiale pour la première année :{" "}</span>
                            <span className="font-bold text-primary-500 ml-auto">{discountMonth} mois Gratuits</span>
                        </div>
                    )}
                </div>
            </div>);
    }
);

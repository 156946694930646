import clsx from "clsx";
import { useCallback, useMemo, useState } from "react";
import { maxAssets } from "../constant/constant";

interface Params {
    numberOfItems: number;
    error: boolean;
    setNumber: (value: number) => void;
    name: string;
    title: string;
}

const InputNumber = ({ numberOfItems, error, setNumber, name, title }: Params) => {
    const [employeeCount, setEmployeeCount] = useState<number | "">(numberOfItems);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            const numericValue = value.length === 0 ? 0 : parseInt(value, 10);

            if (!isNaN(numericValue)) {
                setEmployeeCount(value.length === 0 ? "" : numericValue < maxAssets ? numericValue : maxAssets);
                if (numericValue < maxAssets) {
                    setNumber(numericValue);
                } else {
                    setNumber(maxAssets)
                }

            }
        },
        [setNumber]
    );

    const inputClasses = useMemo(
        () =>
            clsx("appearance-none w-36 max-w-36 xl:max-w-64 xl:w-64 h-12 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white", {
                "border-red-500": error
            }),
        [error]
    );

    return (
        <div className="flex w-full flex-wrap gap-4 md:gap-8 items-center mb-4">
            <label
                htmlFor={name}
                className="w-64 block tracking-wide text-xl font-semibold"
            >
                {title}
            </label>
            <input
                type="number"
                name={name}
                id={name}
                className={inputClasses}
                value={employeeCount}
                onChange={handleChange}
            />
        </div>
    );
};

export default InputNumber;

import { initialLocation } from "../constant/constant";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {  FormOdooState } from "../types";

const initialState: FormOdooState = {
  numUsers: 1,
  hostingType: "cloud",
  typePlan: "starter",
  isAnnual: true,
  website: false,
  typeMaintenance:null,
  numberOfEmployees: 1,
  selectedProducts: [],
  cost: 0,
  maintenance: false,
  supportImplementation: false,
  totalPrice: 0,
  numberInstallationDays: 3,
  location: initialLocation,
};

const odooSubscriptionSlice = createSlice({
  name: "odooSubscription",
  initialState,
  reducers: {
    setNumUsers: (state, action: PayloadAction<number>) => {
      state.numUsers = action.payload;
    },
    setHostingType: (state, action: PayloadAction<string>) => {
      state.hostingType = action.payload;
    },
    setTypePlan: (state, action: PayloadAction<string>) => {
      state.typePlan = action.payload;
    },
    setIsAnnual: (state, action: PayloadAction<boolean>) => {
      state.isAnnual = action.payload;
    },
    setWebsite: (state, action: PayloadAction<boolean>) => {
      state.website = action.payload;
    },

    setTotalPrice: (state, action: PayloadAction<number>) => {
      state.totalPrice = action.payload;
    },
    setSelectedProducts: (
      state,
      action: PayloadAction<{ value: string; checked: boolean }>
    ) => {
      const { value, checked } = action.payload;
      state.selectedProducts = checked
        ? [...state.selectedProducts, value]
        : state.selectedProducts.filter((product) => product !== value);
    },
    
    setMaintenance: (state, action: PayloadAction<boolean>) => {
      state.maintenance = action.payload;
    },
    setNumberOfEmployees: (state, action: PayloadAction<number>) => {
      state.numberOfEmployees = action.payload;
    },
    setSupportImplementation: (state, action: PayloadAction<boolean>) => {
      state.supportImplementation = action.payload;
    },

    setLocation: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
    },

    setUrlServer: (state, action: PayloadAction<string>) => {
      state.urlServer = action.payload;
    },

    setMaintenanceType: (state, action: PayloadAction<string>) => {
      state.typeMaintenance = action.payload;
    },

    setNumberInstallationDays: (state, action: PayloadAction<number>) => {
      state.numberInstallationDays = action.payload;
    },

    cleanProduct: (state,) => {
      state.selectedProducts = []
    },

    cleanUrlServer: (state,) => {
      state.urlServer = undefined
    },

    cleanMaintenanceType: (state,) => {
      state.typeMaintenance = null 
    },
  },
});

export const {
  setNumUsers,
  setHostingType,
  setTypePlan,
  setIsAnnual,
  setWebsite,
  setTotalPrice,
  setSelectedProducts,
  setMaintenance,
  setSupportImplementation,
  setNumberOfEmployees,
  setLocation,
  setNumberInstallationDays,
  setMaintenanceType,
  cleanProduct,
  cleanUrlServer,
  setUrlServer,
  cleanMaintenanceType
} = odooSubscriptionSlice.actions;

export default odooSubscriptionSlice.reducer;


import React from 'react'
import { DataModel } from '../../../types/model/data';
import ImageMotion from '../../commons/wrappers/imageMotion';
import { Images } from '../../../constant';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { createSlug } from '../../../utils/createSlug';

export const FlowComponent = ({ title, description, image, alignLeft }: DataModel) => {
    const containerClasses = clsx(
        'flex',
        'flex-col',
        'items-center',
        'px-0',
        '-py-0',
        'mx-0',
        'w-full', 'px-4 md:px-8 xl:px-0',
        'flex-col-reverse ',
        'gap-x-8 py-10',
        alignLeft
            ? clsx('sm:flex-row-reverse', 'lg:pr-24', 'lg:gap-16')
            : 'sm:flex-row',
        'sm:justify-between',

        !alignLeft && '2xl:gap-32 lg:gap-24'
    );


    const imageView = <ImageMotion
        key={title}
        classNames=''
        Component={
            <img
                src={image ?? Images.background as string}
                alt={title}
                width={593}
                height={503}

            />

        }
    />

    return (

        <div className={containerClasses} >
            <div className='  w-full md:w-1/2  ' >

                <h4 className='font-medium text-primary-700 text-2xl lg:text-4xl space-x-4 md:space-x-0 font-semibold flex flex-row' >
                    <span>{title}</span>
                </h4>
                <div className='my-4 mt-10 block sm:hidden w-full'>
                    {imageView}
                </div>
                <div className="flex flex-col  items-left my-6 md:my-0   mx-0">
                    <div />
                    <div className=' my-2 lg:my-8 w-full font-normal leading-8 flex flex-col gap-4'>
                        {description.split('\n').map(value => (
                            <p key={value.replaceAll(' ', "_")}>{value}</p>
                        ))}
                    </div>
                </div>

                <Link to={`/products/${createSlug(title)}`} className='mt-8 px-16 sm:w-4/5 xl:w-64 py-6 border-2  border-primary-300 bg-transparent hover:bg-primary-300 hover:text-white font-semibold  transition-all duration-200 rounded-full  h-8  flex items-center justify-center'> Voir Plus</Link>
            </div>
            <div className={clsx('hidden sm:block mb-8 w-full md:w-1/2 h-full   flex items-center justify-start', { 'justify-center': alignLeft })} >
                {imageView}
            </div>


        </div >

    );
}

import clsx from "clsx";
import React from "react";

interface Step {
    label: string;
    step: number;
}

interface StepperProps {
    steps: Step[];
    activeStep: number; goStep: (value: number) => void

}

const StepperComponent: React.FC<StepperProps> = ({ steps, goStep, activeStep }) => {

    return (
        <div className="w-full max-w-2xl  ">
            <ol className="flex items-center w-full  sm:py-3 space-x-2  text-xs sm:text-md font-medium text-center text-gray-500  sm:py-4 sm:space-x-4 rtl:space-x-reverse">
                {steps.map(({ step, label }) => (
                    <button
                        key={label}
                        onClick={e => activeStep > step && goStep(step - 1)}
                        className={clsx("w-full sm:w-auto flex items-center  ", {
                            "text-blue-600 dark:text-blue-500": activeStep > step,
                            "text-gray-600 font-semibold": activeStep === step,
                        })}
                    >
                        <span
                            className={clsx(
                                "sm:hidden flex items-center justify-center w-6 h-6 me-2 text-xs  sm:text-sm border-gray-500 border  rounded-full shrink-0",

                                {
                                    "bg-blue-600 text-white border-0": activeStep > step,
                                    "bg-gray-600 text-white font-semibold": activeStep === step,
                                }
                            )}
                        >
                            {step}
                        </span>
                        <span className="hidden sm:inline-flex sm:ms-2 sm:text-lg ">
                            {label}
                        </span>
                        {step < steps.length && (
                            <div
                                className={clsx(
                                    "h-1 sm:hidden  rounded-full  w-full transform-y-1/2 transition-all ease-in delay-200",
                                    {
                                        " bg-blue-600 ": activeStep > step,
                                        "bg-gray-400": activeStep < step
                                    }
                                )}
                            ></div>
                        )}
                        {step < steps.length && (
                            <svg
                                className=" -rotate-90 hidden sm:block size-4 stroke-2 ms-2 sm:ms-4"
                                strokeWidth={1.5}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                                />
                            </svg>
                        )}
                    </button>
                ))}
            </ol>
        </div>
    );
};

export default StepperComponent;

import React from 'react'
import Question from './question_component'
import ImageMotion from '../../commons/wrappers/imageMotion'
import { H2 } from '../../commons/h_component'

export default function Questions() {
    return (
        <section id='section-faq' className='md:pt-24 w-full h-full flex items-center justify-center  my-16'>
            <div className='container w-full h-full px-4 2xl:px-48 md:px-14 gap-16  flex flex-col  items-center justify-center'>
                <ImageMotion
                    classNames=''
                    Component={
                        <H2 classNames='text-2xl lg:text-4xl font-semibold flex flex-col gap-4' Component={
                            <>
                                <p>Vous avez des soucis ?</p>
                                <p>Nous vous proposons des solutions :</p>
                            </>

                        } />


                    }
                />
                <div className='w-full h-full'>
                    <Question></Question>
                </div>
            </div>
        </section>
    )
}

import { Employe } from "..";
import { Images } from "../../constant";

const list_employe: Employe[] = [
    {
        name: "Sado Frazer",
        role: "CEO",
        description: "Fondateur et principal visionnaire, Frazer est la force motrice de l'entreprise. Il aime s'occuper en participant au développement de stratégies logicielles, marketing et d'expérience client.",
        image: Images.fraser
    },
    {
        name: "Raquel Fabiani TOUOYEM",
        role: "CTO",
        description: "Raquel est une personne emblématique. On sent qu'il aime ce qu'il fait. Il supervise le personnel technique de l'entreprise et met à profit toutes ses connaissances et son expertise en matière d'architecture et de cybersécurité.",
        image: Images.raquiel
    }, {
        name: "Hippolyte MOFFO",
        role: "COO",
        description: "Hippolythe aime les défis. Avec de nombreuses années d'expérience en tant que directeur des ventes dans l'industrie du logiciel, Hippolythe a aidé l'entreprise à se hisser au niveau où elle se trouve aujourd'hui. Mich est l'un des meilleurs cerveaux qui soient.",
        image: Images.hippo
    }/*, {
        name: "Iris Joe",
        role: "CFO",
        description: "Grâce à son expérience internationale, Iris nous aide à comprendre les chiffres et à les améliorer. Elle est déterminée à réussir et met ses compétences professionnelles au service de l'entreprise pour la faire progresser.",
        image: "c"
    },*/
];

export default list_employe;
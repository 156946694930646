import { faAddressBook, faBell, faBuilding, faChartBar, faClock, faEnvelope, faHandshake } from "@fortawesome/free-regular-svg-icons";
import { faBug, faChartLine, faChartPie, faClipboardList, faCode, faDatabase, faDesktop, faExchange, faFileAlt, faHistory, faList, faMoneyCheckAlt, faPaintBrush, faProjectDiagram, faPuzzlePiece, faShieldAlt, faSimCard, faSlidersH, faTasks, faThLarge, faTicketAlt, faTruck, faUsers, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { ProductPaths } from "../../../constant";
import { ProductServiceModel } from "../../model/service_product_model";

export const glpiData: ProductServiceModel[] = [
    {
        "name": "CMDB",
        "description": "La base de données de gestion de configuration (CMDB) de GLPI est un module qui permet d'enregistrer et de suivre des informations détaillées sur les actifs informatiques et informatiques d'une organisation. Ces actifs peuvent inclure du matériel (ordinateurs, serveurs, imprimantes, etc.), des logiciels, des systèmes d'exploitation, des périphériques, des licences et même des installations logicielles.",
        "exImageUrl": ProductPaths.cmdb,
        "fonctionnalite": [
            {
                "name": "Matériel",
                "description": "La fonctionnalité 'Matériel' permet de suivre et de gérer tous les équipements informatiques de votre organisation. Vous pouvez enregistrer des informations détaillées sur chaque appareil, y compris les ordinateurs, les serveurs, les imprimantes et autres périphériques. Cette fonctionnalité vous aide à maintenir un inventaire à jour, à planifier les remplacements et les mises à jour, et à assurer une gestion efficace des actifs matériels.",
                "icon": faDesktop
            },
            {
                "name": "Datacenter",
                "description": "Avec la gestion du 'Datacenter', vous pouvez suivre et administrer tous les composants critiques de votre centre de données. Cette fonctionnalité inclut la gestion des serveurs, des racks, des unités de stockage et des équipements réseau. Elle vous permet de visualiser l'infrastructure physique, de surveiller les performances et de garantir la continuité des opérations grâce à une gestion proactive des ressources du datacenter.",
                "icon": faDatabase
            },
            {
                "name": "Logiciel",
                "description": "La gestion des 'Logiciels' vous permet de suivre et d'organiser toutes les applications installées au sein de votre organisation. Vous pouvez enregistrer les licences, surveiller les versions et gérer les installations logicielles sur différents appareils. Cette fonctionnalité assure la conformité des licences et aide à prévenir les problèmes de compatibilité ou de sécurité liés aux logiciels obsolètes ou non autorisés.",
                "icon": faCode
            },
            {
                "name": "Impacts et dépendances",
                "description": "La fonctionnalité 'Impacts et dépendances' vous aide à comprendre les relations et les dépendances entre différents actifs informatiques. En cartographiant ces connexions, vous pouvez évaluer les impacts potentiels des changements ou des pannes sur l'ensemble de votre infrastructure. Cela facilite la planification des interventions, la gestion des risques et la prise de décisions informées pour maintenir la continuité des services.",
                "icon": faProjectDiagram
            },
            {
                "name": "Cartes SIM",
                "description": "La gestion des 'Cartes SIM' permet de suivre et d'administrer toutes les cartes SIM utilisées dans les appareils mobiles de votre organisation. Vous pouvez enregistrer les informations sur les opérateurs, surveiller les coûts et gérer les abonnements. Cette fonctionnalité vous aide à optimiser l'utilisation des ressources mobiles et à contrôler les dépenses liées aux communications sans fil.",
                "icon": faSimCard
            },
            {
                "name": "Tableaux de bord",
                "description": "Les 'Tableaux de bord' fournissent une vue d'ensemble en temps réel de l'état de votre infrastructure informatique. Ils affichent des indicateurs clés de performance (KPI) et des rapports visuels pour vous aider à surveiller les actifs, à identifier les tendances et à prendre des décisions basées sur des données précises. Cette fonctionnalité améliore la visibilité et la gestion stratégique de vos ressources informatiques.",
                "icon": faChartPie
            }
        ]
    }

    ,
    {
        name: "Helpdesk",
        description:
            "Le module HelpDesk de GLPI est un système de gestion des tickets (ticket management system) qui permet aux utilisateurs de soumettre des demandes d'assistance, de suivre leur progression et de recevoir des réponses. Il offre une large gamme de fonctionnalités pour gérer efficacement les incidents, les demandes de service et les autres problèmes informatiques.",
        exImageUrl:
            ProductPaths.helpdesk,
        fonctionnalite: [
            {
                name: " Tickets",

                description: "GLPI permet aux utilisateurs de choisir entre le processus d’incident ou de demande (catalogue de services). Chaque service peut-être attribué à un groupe spécifique d’utilisateurs/profils ou à un responsable et suivre un workflow précis.",
                icon: faTicketAlt,
            },
            {
                name: "Problèmes",

                description: "La gestion des Problèmes vous permet d’identifier, suivre et planifier des actions ou des décisions pour traiter vos incidents avant qu’ils ne surviennent de nouveau.",
                icon: faBug,
            },

            {
                name: "Statistiques",

                description: "Dans GLPI vous pouvez afficher des statistiques sur les tickets, les enquêtes de satisfaction, le temps moyen et la durée moyenne réelle du traitement, les composants des ordinateurs, le nombre de tickets alloués à chaque asset, etc.",
                icon: faChartLine,
            },
            {
                name: "Formulaires",

                description: "Créer des formulaires personnalisés et faciles d’accès pour les utilisateurs qui souhaitent créer un ou plusieurs tickets ou modifications.",
                icon: faFileAlt,
            },
            {
                name: "Tickets et changements récurrents",
                description: "Il est possible de programmer l’ouverture des tickets de manière récurrente. Par exemple: ouvrez un ticket tous les vendredis matin pour faire valider la sauvegarde sur bande qui doit s’effectuer le soir.",
                icon: faClock,
            }, {
                name: "Changements",

                description: "La gestion des Changements vous permet de contrôler la mise en place de la résolution d’un problème (au sens GLPI ou non) ou la mise en place d’un nouvel élément matériel/logiciel ou processus dans votre SI. Analyse des impacts, liste des contrôles à effectuer pré-changement, plan de déploiement, de repli, liste des vérifications post-changement, processus de validation : autant d’outils qui permettent d’assurer un changement sereinement.",
                icon: faExchange,
            },
        ],
    },
    {
        name: "Gestion Financiere",
        description:
            "Le module de gestion financière de GLPI permet aux entreprises de suivre et de gérer les coûts associés à leurs actifs informatiques. Il offre une variété de fonctionnalités pour suivre les dépenses, les budgets et les contrats,",
        exImageUrl:
            ProductPaths.financiere,
        fonctionnalite: [
            /*{
                name: "Gestion",
                description: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi voluptatum enim consequuntur ducimus. Eum, laborum consequuntur iure officiis hic dolore repellendus culpa accusamus neque ratione ipsam, cumque illo cupiditate? Aut.",
                icon: "",
            },
            {
                name: "Administration",
                description: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi voluptatum enim consequuntur ducimus. Eum, laborum consequuntur iure officiis hic dolore repellendus culpa accusamus neque ratione ipsam, cumque illo cupiditate? Aut.",
                icon: "",
            },*/
            {
                name: "Contrats",
                description: "Il est possible de matérialiser les contrats liés à vos assets et services d’assistance dans GLPI. Pour le contrat, vous pouvez définir les éléments suivants : Période ; Période de facturation ; Type de renouvellement ; Heures d’intervention dans le cadre du contrat d’assistance, etc.",
                icon: faHandshake,
            },
            {
                name: "Fournisseurs",
                description: "GLPI vous permet d’intégrer la liste de vos fournisseurs. Chaque fournisseur peut alors être lié à d’autres objets GLPI : Tickets ; Contrats ; Problèmes ; Changements ; Parc ; etc.",
                icon: faTruck,
            },
            {
                name: "Budgets",

                description: " Vous pouvez mettre en œuvre et suivre vos budgets en liaison directe avec votre parc matériel/logiciel et aux services d’assistance que vous fournissez avec GLPI (coût du support, TCO, etc…). Un budget peut-être défini par : type, valeur, période, lieu.",
                icon: faMoneyCheckAlt,
            },
            {
                name: "Contacts",

                description: "Qui est le commercial en charge de mon contrat de licence ? Quel est le numéro de téléphone du support technique de mon contrat de maintenance ? Des questions pour lesquelles GLPI peut vous apporter une réponse grâce aux contacts enregistrés sur vos fournisseurs.",
                icon: faAddressBook,
            },
        ],
    },
    {
        name: "Gestion de projet",
        description:
            "Le module de gestion de projet de GLPI permet aux utilisateurs de planifier, organiser et suivre leurs projets informatiques. Il offre une variété de fonctionnalités pour créer des tâches, assigner des ressources,",
        exImageUrl:
            ProductPaths.projectManagement,
        fonctionnalite: [
            {
                name: "Kanban",
                description: "Le Kanban de GLPI est un outil visuel pour gérer les tickets d'assistance. Il permet de visualiser l'avancement des demandes, d'améliorer la collaboration et de limiter le nombre de tickets en cours. Les tickets sont représentés par des cartes virtuelles qui se déplacent à travers différentes étapes, comme 'À faire', 'En cours' et 'Terminé'.",
                icon: faThLarge
            },
            {
                name: "GANTT",
                description: "Le module Gantt de GLPI permettait de visualiser les projets sous forme de diagrammes de Gantt pour planifier et suivre les tâches, les dépendances et l'avancement des projets.",
                icon: faChartBar,
            },

            {
                name: "Rappels",
                description: " Définissez des rappels par e-mail ou dans l'application pour les tâches et événements importants, garantissant que vous ne manquerez rien et que les délais seront respectés. Améliorez la responsabilisation et la gestion du temps des équipes.",

                icon: faBell,
            },
            {
                name: "Rapports",
                description: "Générer des rapports personnalisés sur les projets, les tâches et les SLA pour un suivi précis et une analyse approfondie de l'avancement, des tendances et des domaines d'amélioration. Exportez les rapports pour un partage aisé.",
                icon: faClipboardList,
            },
            {
                name: "Tâches",
                description: "Planifiez, organisez et suivez vos tâches pour une collaboration efficace, en les attribuant, en définissant des priorités et en visualisant leur progression. Facilitez la communication en enrichissant les tâches avec des commentaires et des pièces jointes.",
                icon: faTasks,
            },
        ],
    },
    {
        "name": "Administration",
        "description": "L'administration dans GLPI regroupe l'ensemble des fonctionnalités et des outils nécessaires pour gérer la configuration et les utilisateurs du logiciel. Ces fonctionnalités sont réservées aux administrateurs et garantissent le bon fonctionnement et la sécurité de l'application.",
        "exImageUrl": ProductPaths.administration,
        "fonctionnalite": [
            {
                "name": "Moteur de règles (workflow)",
                "description": "Optimisez vos processus avec notre moteur de règles avancé, permettant d'automatiser les tâches et de définir des workflows personnalisés pour une gestion plus efficace.",
                "icon": faSlidersH
            },
            {
                "name": "Restrictions d'accès",
                "description": "Assurez la sécurité et la confidentialité des informations en contrôlant les accès des utilisateurs à différentes sections de l'application selon leurs rôles et responsabilités.",
                "icon": faUserShield
            },
            {
                "name": "Profils",
                "description": "Simplifiez la gestion des utilisateurs en créant des profils spécifiques, facilitant l'attribution des droits et des permissions adaptés à chaque groupe d'utilisateurs.",
                "icon": faUsers
            },
            {
                "name": "Groupes",
                "description": "Organisez vos utilisateurs en groupes pour une gestion simplifiée et une communication plus efficace, en attribuant des rôles et des responsabilités de manière collective.",
                "icon": faBuilding
            },
            {
                "name": "Entités (multi-tenants)",
                "description": "Gérez plusieurs entités indépendantes au sein d'une même instance GLPI, offrant une flexibilité et une séparation des données adaptée aux grandes organisations.",
                "icon": faBuilding
            },
            {
                "name": "Historique des journaux",
                "description": "Suivez toutes les activités et modifications effectuées dans le système grâce à un historique détaillé des journaux, garantissant transparence et traçabilité.",
                "icon": faHistory
            }
        ]
    }
    , {
        "name": "Configuration",
        "description": "Personnalisez GLPI : explorez les fonctionnalités de configuration pour ajouter votre logo, sélectionnez la palette de couleurs et configurez les plugins. Dans cette section, vous pouvez également gérer les SLA et les notifications.",
        "exImageUrl": ProductPaths.configuration,
        "fonctionnalite": [
            {
                "name": "SLA",
                "description": "Gérez les accords de niveau de service (SLA) pour garantir que les demandes des utilisateurs soient traitées en temps opportun. Définissez des délais, des priorités et des règles pour améliorer la satisfaction des utilisateurs et la performance de votre équipe de support.",
                "icon": faSlidersH
            },
            {
                "name": "GLPI branding",
                "description": "Personnalisez l'apparence de votre instance GLPI en ajoutant votre logo et en sélectionnant une palette de couleurs qui reflète l'identité de votre organisation. Cela permet de renforcer la reconnaissance de votre marque au sein de votre équipe.",
                "icon": faPaintBrush
            },
            {
                "name": "Plugins",
                "description": "Étendez les fonctionnalités de GLPI en installant et en configurant des plugins. Ces extensions permettent d'ajouter des fonctionnalités spécifiques pour répondre aux besoins uniques de votre organisation, augmentant ainsi la flexibilité et la puissance de GLPI.",
                "icon": faPuzzlePiece
            },
            {
                "name": "Notifications",
                "description": "Configurez les notifications pour informer les utilisateurs et les administrateurs des événements importants. Vous pouvez personnaliser les alertes pour divers événements, tels que la création de tickets, les mises à jour de statut et les échéances, assurant ainsi une communication efficace.",
                "icon": faEnvelope
            },
            {
                "name": "Authentification",
                "description": "Gérez les méthodes d'authentification pour sécuriser l'accès à votre instance GLPI. Intégrez des solutions telles que LDAP, SSO et autres systèmes d'authentification pour garantir une connexion sécurisée et simplifiée pour vos utilisateurs.",
                "icon": faShieldAlt
            },
            {
                "name": "Listes déroulantes",
                "description": "Personnalisez les listes déroulantes utilisées dans GLPI pour mieux refléter les processus et la terminologie de votre organisation. Cette fonctionnalité vous permet d'ajuster les options disponibles pour les utilisateurs, améliorant ainsi la précision et la pertinence des données saisies.",
                "icon": faList
            }
        ]
    }
    ,
];


import React from 'react';

interface ServiceQuestionsProps {
    onServiceChange: (value: boolean) => void;
}

const ServiceQuestions: React.FC<ServiceQuestionsProps> = ({ onServiceChange }) => {


    const handleWebsiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onServiceChange(e.target.value === 'yes');
    };

    return (
        <div className="space-y-6 text-md ">


            <div className="py-4 md:w-4/5">
                <h3 className="font-semibold text-xl mb-4 xl:mb-8">Site web intégré à l'instance Odoo ?</h3>
                <p className="pl-2 text-gray-800 ">
                    {/* Transformez votre présence en ligne avec un site web professionnel et attrayant. Nos solutions sur mesure vous aideront à atteindre vos objectifs commerciaux et à engager vos clients. */}
                    Pour des performances optimales, la taille de l'instance Odoo ou la formule d'abonnement varie en fonction de ce paramètre
                </p>
                <div className="pl-2 flex items-center mt-4 ">
                    <input
                        type="radio"
                        id="websiteYes"
                        name="website"
                        value="yes"
                        onChange={handleWebsiteChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <label htmlFor="websiteYes" className="ml-3 block text-gray-700">Oui</label>
                </div>
                <div className="pl-2 flex items-center mt-2">
                    <input
                        type="radio"
                        id="websiteNo"
                        name="website"
                        value="no"
                        defaultChecked

                        onChange={handleWebsiteChange}
                        className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                    <label htmlFor="websiteNo" className="ml-3 block text-gray-700">Non</label>
                </div>
            </div>
        </div>
    );
};

export default ServiceQuestions;

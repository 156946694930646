import background from "../assets/background1.jpg";
import edit from "../assets/edit.svg";
import build from "../assets/build.svg";
import all03 from "../assets/all03.svg";
import logo from "../assets/cloudapps-logo-v3.png";
import odoo from "../assets/odoo.png";
import glpi from "../assets/glpi.png";
import bigbluebutton from "../assets/bigbluebutton.jpg";
import saas from "../assets/saas.png";
import paas from "../assets/paas.png";
import consulting1 from '../assets/consulting.png'
import bg1 from '../assets/bg1.jpeg'
import bg2 from '../assets/bg2.png'
import bg3 from '../assets/bg3.png'
import bg4 from '../assets/bg4.png'
import bg5 from '../assets/bg4.jpeg'
import logoFooter from '../assets/logo.png'
// import bg5 from '../assets/cloudApp.jpeg'
import cloudConsulting from '../assets/cloudConsulting.jpeg'
import appOdoo from "../assets/app_odoo1.png"
import arrow from '../assets/utils/arrow.svg'
import fraser from '../assets/employes/frazer.jpeg'
import raquiel from '../assets/employes/raquiel.jpeg'
import hippo from '../assets/employes/hippo.jpeg'
import blackForest from "../assets/blackforest.png"
import eshley from "../assets/eshley.png"
import montreal from "../assets/montreal.png"
import sea_time from "../assets/sea_time.png"
import simpleStar from '../assets/simple_star.png'
import blank from '../assets/blank.png'
import mtn from "../assets/mtn.png"
import orange from "../assets/orange.png"
import visa from "../assets/VISA.png"
import masterCard from "../assets/MasterCard.png"
import americanExpress from "../assets/AmericanExpress.png"
import maestro from "../assets/Maestro.png"

import corner_1 from "../assets/utils/corner_1.svg"
import green_highlight_03 from "../assets/utils/green_highlight_03.svg"
import secondary_arrow_sm_03 from "../assets/utils/secondary_arrow_sm_03.svg"
import yellow_highlight_bold_04 from "../assets/utils/yellow_highlight_bold_04.svg"


const imagesApp = {
    background,
    bg5,
    cloudConsulting,
    logoFooter,
    bg1, bg2, bg3, bg4, arrow,
    edit,
    appOdoo,
    fraser, raquiel, hippo,
    build,
    all03,
    logo,
    blank, blackForest, simpleStar, sea_time, eshley, montreal,
    // logo3,
    glpi,
    bigbluebutton,
    odoo,

    consulting1,
    paas,
    saas, mtn, orange,
    visa, maestro, masterCard, americanExpress,
    corner_1, yellow_highlight_bold_04, secondary_arrow_sm_03, green_highlight_03
};

export default imagesApp;

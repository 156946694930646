import clsx from 'clsx'
import React from 'react'
import { H1, H2 } from '../../components/commons/h_component'
import MotionWrap from '../../components/commons/motionWrapper'
import ImageMotion from '../../components/commons/wrappers/imageMotion'
import GlpiService from '../../components/glpi_service_component'
import { ProductPaths } from '../../constant'
import { whileInViewImage } from '../../constant/constant'
import imagesApp from '../../constant/images'
import { styles } from '../../styles'
import { AppConstant } from '../../types/constants/app_constant'


const whileInView: Object = whileInViewImage;

export default function GlpiPage() {

    return (
        <div className="flex flex-col items-center bg-white relative items-center lg:h-full">

            <section id="text" className='bg-gray-50 h-full w-full flex justify-center pt-36 lg:pt-40 pb-16 '>
                <div className='container w-full h-full flex flex-col items-center  px-4 xl:px-16 2xl:px-0 gap-24 '>
                    <ImageMotion
                        classNames=""
                        Component={

                            <H1 classNames={styles.serviceStyle['title']} Component={

                                <span >CloudApps GLPI</span>
                            } />

                        }
                    />
                    <div className='flex flex-col  gap-8  lg:gap-16 xl:gap-24 items-center justify-center'>
                        <MotionWrap classNames='w-full   md:w-3/4  h-full' Component={
                            <div className='w-full h-full text-lg leading-8 flex flex-col gap-8 text-justify'>
                                <p className=' '>{AppConstant.glpiDescription1}</p>
                                <p>{AppConstant.glpiDescription2}</p>
                            </div>} />

                        <div className='w-full   h-full'>

                            <ImageMotion
                                classNames="w-full h-full flex items-center justify-center"
                                whileInView={whileInView}
                                Component={
                                    <img src={ProductPaths.glpi} alt="products" className='object-fit w-full max-w-[850px]' />

                                }
                            />
                        </div>

                    </div>


                </div>
            </section>


            <section className='min-h-screen w-full flex justify-center py-24 '>
                <div className=' container px-4 xl:px-16 2xl:px-0 gap-14 w-full h-full flex flex-col   items-center lg:gap-24'>
                    <ImageMotion
                        classNames=""
                        Component={
                            <H2 classNames={clsx(styles.serviceStyle['title-prod'], "mb-8 text-center md:text-start font-semibold")} Component={"Optimisez Votre Gestion IT avec GLPI"} />


                        }
                    />
                    <div className=' flex  flex-col md:flex-row gap-8 lg:gap-24 items-center justify-between w-full flex-col-reverse' >

                        <div className='w-full h-full'>
                            <GlpiService></GlpiService>
                        </div>


                    </div>

                </div>
            </section>

            <section className="text-center pt-24 pb-24 h-full bg-gray-50 w-full">


                <MotionWrap classNames='w-full  h-full' Component={
                    <div className="container mx-auto px-4 h-full flex flex-col items-center justify-center gap-8 ">
                        <H2 classNames={clsx(styles.serviceStyle["title-prod"], "md:w-3/4 flex flex-col gap-6 ")} Component={
                            <p>
                                <p><span className="font-semibold">Optimisez </span><br /> </p>
                                <p> la<span className="text-gray-800 font-medium leading-8 text-primary-300"> croissance de votre activité<span className='font-semibold text-primary-500'> avec CloudApps GLPI</span></span></p>
                            </p>
                        } />
                        <div className='rotate-180'>
                            <img src={imagesApp.arrow} className=" mx-auto mb-2 animate-bounce" alt="Arrow" loading="lazy" />

                        </div>
                        <a href="/subcribe-glpi" className="bg-primary-500 text-white py-4 px-8 rounded-lg hover:shadow-xl  mb-3 hover:bg-primary-700 text-lg font-bold mx-auto transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ...">
                            Souscrivez dès maintenant  !
                        </a>
                        <img src={imagesApp.arrow} className="mx-auto mb-2 animate-bounce " alt="Arrow" loading="lazy" />
                    </div>
                }
                />



            </section>

        </div>
    )
}

"use client"
import  {  ReactNode } from 'react';
import { motion } from 'framer-motion';

interface MotionWrapProps {
    classNames: string;
    Component: ReactNode;
    whileInView?: Object;

}

const MotionWrap = ({ classNames, Component, whileInView }: MotionWrapProps) => {
    const result: any = whileInView ??  { y: [100, 50, 0], opacity: [0, 0, 1] };

    return (
        <motion.div
            whileInView={result}
            transition={{ duration: .8, delayChildren: 1 }}
            className={`${classNames}`}
        >
            {Component}
        </motion.div>
    );
};

export default MotionWrap;
import clsx from 'clsx';
import React, { useCallback, useState } from 'react'


interface FAQ {
    question: string;
    answer: string | string[];
}

const faqs: FAQ[] = [
    {
        question: "CloudApps, c'est quoi ?",
        answer: "CloudApps est un expert en solutions digitales d'entreprise qui accompagne les entreprises dans la mise en place et l'utilisation d'Odoo et GLPI pour optimiser leurs opérations et booster leur productivité.",
    },
    {
        question: "Que fait CloudApps pour vous ?",
        answer: ["Audit gratuit : On ausculte vos besoins et vous propose un plan d'attaque sur-mesure.",
            "Mise en place sur-mesure : Odoo et GLPI s'implantent chez vous comme des rois, prêts à vous servir.",
            "Formation et accompagnement : Votre équipe devient des virtuoses d'Odoo et GLPI.",
            "Maintenance et évolution : Vos outils restent performants et à jour, comme un bon sherpa qui connaît tous les chemins."],
    }, {
        question: "Pourquoi choisir CloudApps ?",
        answer: ["Des solutions performantes et adaptées à vos besoins.",
            "Un accompagnement personnalisé pour une réussite totale.",
            "Une équipe réactive et à votre écoute."],
    }, {
        question: "Comment nous contacter ?",
        answer: "Téléphone, email, site web... On est à votre disposition par tous les sentiers numériques !",
    },
    {
        question: "Quel est le coût des services de CloudApps ?",
        answer: "Le coût de nos services dépend de vos besoins spécifiques et de l'envergure de votre projet. Nous vous proposons des devis personnalisés et transparents pour que vous puissiez budgétiser votre projet en toute sérénité.",
    }
];


export default function Question() {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const toggleAnswer = useCallback(
        (index: number) => {
            setOpenIndex(openIndex === index ? null : index);
        },
        [openIndex],
    )

    return (
        <div className="pb-12  lg:px-16 2xl:px-36">
            <div className="w-full mx-auto space-y-8 flex items-center justify-center flex-col">

                <div className="w-full lg:p-6 gap-4 flex flex-col">
                    {faqs.map((faq, index) => (
                        <div key={faq.answer.slice(0, 10) as string} className={clsx('transition-all duration-100 ease-out overflow-hidden  px-4 lg:px-8 border rounded-2xl border-gray-200 py-8', { ' border-2 border-primary-500': openIndex === index })}>
                            <button
                                type="button"
                                className=" w-full text-left flex justify-between items-center text-gray-700"
                                onClick={() => toggleAnswer(index)}
                            >
                                <span className="text-lg font-semibold">{faq.question}</span>
                                <svg
                                    className={`w-6 h-6 transform transition-transform ${openIndex === index ? 'rotate-180' : ''}`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                </svg>
                            </button>
                            <p id={`faq-answer-${index}`} className={`px-4 mt-8 text-gray-600 ${openIndex === index ? '' : 'hidden'}`}>
                                {Array.isArray(faq.answer) ? (
                                    <ul className="list-disc list-inside space-y-4">
                                        {faq.answer.map((item, idx) => {
                                            const [boldText, ...rest] = item.split(':');

                                            return (
                                                <li key={item}>

                                                    <span className={clsx('', { 'font-bold ': rest.length > 0 })}>
                                                        {boldText} {rest.length > 0 ? ':' : ''}
                                                    </span>
                                                    <span>{rest.join(':')}</span>
                                                </li>
                                            );
                                        }

                                        )}
                                    </ul>
                                ) : (
                                    <p>{faq.answer}</p>
                                )}
                            </p>
                        </div>
                    ))}
                </div>

                <h3 className='font-bold text-xl lg:text-2xl'> Prêt à booster votre entreprise avec CloudApps ?</h3>
            </div>
        </div>
    );
};



import { clsx } from "clsx";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUrlServer, isValidUrl, normaliseUrl } from "../../../constant/functions";
import { AppDispatch, RootState } from "../../../store";
import { setUrlServer } from "../../../store/odooSubscriptionSlice";

interface ModalProps {
  onClose: (e: any) => void;
  company: string;
  isGlpi: boolean
}


const ModalUrlConfForm: React.FC<ModalProps> = ({ onClose, company, isGlpi }) => {
  const dispatch = useDispatch<AppDispatch>();


  const {
    urlServer
  } = useSelector((state: RootState) => state.odooSubscription);


  const [url, setUrl] = useState<string>(urlServer ?? getUrlServer({ company }))

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = normaliseUrl(e.target.value);

      setUrl(value);
    },
    []
  );

  const isValidUrlInput = useMemo(() => isValidUrl(url), [url]);

  const handleSave = (e: any) => {
    e.preventDefault();
    if (isValidUrlInput) { dispatch(setUrlServer(url)) }
    onClose(e)
  };

  const handleResetDefault = (e: any) => {
    e.preventDefault();
    setUrl(getUrlServer({ company }))
    // onClose(e)
  };

  return (
    <div className="space-y-4 text-base  text-gray-800">
      <span >
        Si vous souhaitez modifier l'URL de votre instance Odoo, vous devez disposer au préalable d'un nom de domaine, et vous devez créer un enregistrement DNS lié à cette URL et qui pointe vers notre adresse (vip.ic-cloudapps.com).        </span>
      <div>
        <div className="w-full flex flex-wrap gap-y-6 font-medium text-gray-700 gap-x-4 items-center">
          <label htmlFor="url" className="block uppercase font-medium">
            url
          </label>
          <input

            id="url"
            value={url}
            onChange={handleChange}
            className={clsx("mt-1 block  flex-grow px-3 py-2 bg-white border border-gray-500 rounded shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-blue-600 sm:text-sm"
              , {
                "border-red-500": !isValidUrlInput
              }
            )}
          />

          <button
            onClick={handleResetDefault}
            // type="submit"
            className="mx-auto px-6 h-10  text-white bg-primary-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded text-lg text-center font-medium"
          >
            Laisser par defaut
          </button>
        </div>

      </div>


      <div className="flex items-center  justify-between pt-8 md:pt-16">
        <button
          onClick={onClose}
          type="button"
          className=" px-8 md:px-16 py-3 text-white bg-red-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-lg text-center font-semibold"
        >
          Fermer
        </button>

        <button
          onClick={handleSave}
          // type="submit"
          className=" px-8 md:px-16 py-3 text-white bg-primary-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-lg text-center font-semibold"
        >
          Enregister
        </button>
      </div>
    </div>
  );
};

export default ModalUrlConfForm;



import React, { ReactNode } from 'react';

interface ModalProps {
    isOpen: boolean;
    toggleModal: (e: any) => void;
    title: string;
    children: ReactNode
}

const Modal: React.FC<ModalProps> = ({ isOpen, toggleModal, title, children }) => {
    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-y-auto overflow-x-hidden"
            aria-hidden="true"
        >
            <div className="relative p-4 w-full max-w-4xl max-h-full">
                <div className="relative bg-white rounded-lg shadow  px-6">
                    {/* Modal header */}
                    <div className="flex items-center justify-between py-4 md:py-5  rounded-t ">
                        <h3 className="text-2xl font-semibold text-gray-900 ">
                            {title}
                        </h3>
                        <button
                            type="button"
                            onClick={toggleModal}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* Modal body */}
                    <div className=" py-4 md:py-5">
                        {/* <ModalOdooForm onClose={toggleModal} /> */}
                        {children}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Modal;
import abonnement from "../assets/products/images/abonnement.png";
import achat from "../assets/products/images/achat.webp";
import assistance from "../assets/products/images/assistance.webp";
import blog from "../assets/products/images/blog.webp";
import comptabilite from "../assets/products/images/comptabilite.webp";
import conges from "../assets/products/images/conges.webp";
import connaissance from "../assets/products/images/connaissance.webp";
import crm from "../assets/products/images/crm.webp";
import discussion from "../assets/products/images/discussion.webp";
import document from "../assets/products/images/document.webp";
import ecommerce from "../assets/products/images/ecommerce.webp";
import elearning from "../assets/products/images/elearning.webp";
import email_marketing from "../assets/products/images/email_marketing_screenshot_02.jpg";
import employe from "../assets/products/images/employe.webp";
import evaluation from "../assets/products/images/evaluation.png";
import events from "../assets/products/images/events.png";
import fabrication from "../assets/products/images/fabrication.webp";
import facturation from "../assets/products/images/facturation.webp";
import feuille_calcul from "../assets/products/images/feuille_calcul.webp";
import feuille_temps from "../assets/products/images/feuille_temps.webp";
import forums from "../assets/products/images/forums.webp";
import inventaire from "../assets/products/images/inventaire.webp";
import iot from "../assets/products/images/iot.png";
import live_chat from "../assets/products/images/live_chat.webp";
import location from "../assets/products/images/location.webp";
import maintenance from "../assets/products/images/maintenance.gif";
import marketingAutomatisation from "../assets/products/images/marketing_automation_screenshot_02.gif";
import noteFrais from "../assets/products/images/note_frais.webp";
import parc from "../assets/products/images/parc.png";
import planification from "../assets/products/images/planification.webp";
import plm from "../assets/products/images/plm.gif";
import pointVente from "../assets/products/images/pointVente.webp";
import project from "../assets/products/images/project.webp";
import qualite from "../assets/products/images/qualite.webp";
import recruitment from "../assets/products/images/recruitment.webp";
import referral from "../assets/products/images/referral.png";
import rendez_vous from "../assets/products/images/rendez_vous.webp";
import service_site from "../assets/products/images/service_site.webp";
import signature from "../assets/products/images/signature.webp";
import siteWeb from "../assets/products/images/site_web.webp";
import sms from "../assets/products/images/sms-marketing_screenshot_01.gif";
import social from "../assets/products/images/social_screenshot_02a.gif";
import sondages from "../assets/products/images/sondages.png";
import studio from "../assets/products/images/studio.webp";
import validation from "../assets/products/images/validation.gif";
import ventes from "../assets/products/images/ventes.webp";
import voip from "../assets/products/images/voip_img_01.png";

import odoo from "../assets/products/odoo_first.webp";
import configuration from "../assets/products/configuration.png";
import projectManagement from "../assets/products/Project-management.png";
import financiere from "../assets/products/financial.png";
import cmdb from "../assets/products/cmdb.png";
import helpdesk from "../assets/products/helpdesk.png";
import administration from "../assets/products/administration.png";
import glpi from "../assets/products/glpi_first.png";

export const pathProducts = {
  abonnement,
  achat,
  assistance,
  blog,
  comptabilite,
  conges,
  connaissance,
  crm,
  discussion,
  document,
  ecommerce,
  elearning,
  email_marketing,
  employe,
  evaluation,
  events,
  facturation,
  fabrication,
  feuille_calcul,
  feuille_temps,
  forums,
  inventaire,
  iot,
  live_chat,
  location,
  maintenance,
  marketingAutomatisation,
  noteFrais,
  parc,
  planification,
  plm,
  pointVente,
  project,
  qualite,
  recruitment,
  referral,
  rendez_vous,
  service_site,
  signature,
  siteWeb,
  sms,
  social,
  sondages,
  studio,
  validation,
  ventes,
  voip,
  odoo,
  glpi,
  projectManagement,
  financiere,
  helpdesk,
  configuration,
  cmdb,
  administration,
};

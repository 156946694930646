import abonnement from '../assets/products/svg/abonnement.svg'
import achat from '../assets/products/svg/achat.svg'
import assistance from '../assets/products/svg/assistance.svg'
import connaissance from '../assets/products/svg/connaissance.svg'
import crm from '../assets/products/svg/crm.svg'
import dashboard from '../assets/products/svg/dashboard.svg'
import discussion from '../assets/products/svg/discussion.svg'
import document from '../assets/products/svg/document.svg'
import email from '../assets/products/svg/email.svg'
import fabrication from '../assets/products/svg/fabrication.svg'
import hr from '../assets/products/svg/hr.svg'
import icon from '../assets/products/svg/icon.svg'
import inventaire from '../assets/products/svg/inventaire.svg'
import location from '../assets/products/svg/location.svg'
import planification from '../assets/products/svg/planification.svg'
import pointVente from '../assets/products/svg/point_vente.svg'
import projet from '../assets/products/svg/projet.svg'
import service_sur_site from '../assets/products/svg/service_sur_site.svg'
import signature from '../assets/products/svg/signature.svg'
import siteWeb from '../assets/products/svg/site_web.svg'
import social from '../assets/products/svg/social.svg'
import studio from '../assets/products/svg/studio.svg'
import timesheets from '../assets/products/svg/timesheets.svg'
import ventes from '../assets/products/svg/ventes.svg'




export const iconProduct = {
    abonnement, achat, assistance, connaissance,
    crm, dashboard, discussion, document, email,
    fabrication, hr, icon, inventaire, location,
    planification, pointVente, projet, service_sur_site,
    signature, siteWeb, social, studio, timesheets,
    ventes

}
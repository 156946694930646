import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './components/footer';
import Navbar from './components/navbar';
import AppRoutes from './routes';

const App: React.FC = () => {
  return (
    <Router>
      <div className="pt-20 overflow-x-hidden"> {/* Pour éviter que le contenu soit caché derrière la navbar fixe */}
        <Navbar />
        <div className='min-h-screen bg-gray-50'>
          <AppRoutes />
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

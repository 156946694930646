import React from 'react'
import SubscriptionGlpiForm from '../../components/subscription/glpi/form_glpi'
import subscriptionStepper from '../../components/subscription/subscriptionStepper'

const steps = [
    { step: 1, label: 'Vérification' },
    { step: 2, label: 'Informations utilisateur' },
    { step: 3, label: 'Validation' },
];

export const SubscriptionGlpiPage = () => {
    
    const SubscriptionWithStepper = subscriptionStepper(SubscriptionGlpiForm,);

    return <SubscriptionWithStepper steps={steps} isGlpiSubscription={true} />;
}


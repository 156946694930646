import { Images } from "../../../constant";
import { MethodeModel } from "../../model/methodeFonctionnement";

export const listMethodes: MethodeModel[] = [
    {
        title: "Approche axée client pour une satisfaction maximale garantie.",
        description:
            "Notre priorité est de mettre le client au centre de toutes nos actions, assurant ainsi une satisfaction maximale grâce à des solutions sur mesure et un support dédié.",
    },
    {
        title: "Vous aider à vous concentrer sur l'essentiel",
        description:
            "Arrêtez de dépenser du temps et de l'énergie pour régler vos problèmes informatiques ou d'architecture. Concentrez vous sur votre business et vos clients afin de les satisfaire pleinement et créer davantage de valeur.",
    },
    {
        title: "Collaboration étroite pour des solutions parfaitement adaptées",
        description:
            "Nous travaillons en étroite collaboration avec nos clients pour comprendre profondément leurs défis et concevoir des solutions parfaitement ajustées à leurs exigences.",
    },

    {
        title: "Mises à jour régulières basées sur votre feedback.",
        description: 'Nous écoutons activement les retours de nos clients pour constamment améliorer nos produits et services, garantissant ainsi une évolution continue et pertinente.'
    },
    {
        title: "Sécurité fiable assurant une croissance sereine et durable.",
        description: 'Nous garantissons une sécurité robuste pour vos données et applications, permettant une croissance sereine et durable de votre entreprise.'
    },
    {
        title:"Support réactif et technologie de pointe pour vous.",
        description:"Nos clients bénéficient d’un support rapide et de solutions technologiques avancées(Conteneurisation,Cloud,déploiements automatisés), leur permettant de rester à la pointe de l'innovation et de la performance."
    }
];

export const listProces: MethodeModel[] = [
    {
        icon: Images.saas,
        title: "SAAS",
        description:
            "Adoptez des solutions SaaS métiers pour une gestion fluide et collaborative de vos processus, accessibles depuis n'importe quel appareil et à tout moment. Simplifiez vos opérations et boostez votre productivité.",
    },
    {
        icon: Images.paas,
        title: "PAAS",
        description:
            "Développez et déployez des applications web et mobiles performantes en toute sécurité sur notre plateforme cloud de pointe. Flexibilité, scalabilité et fiabilité garanties pour concrétiser vos ambitions digitales.",
    },
    {
        icon: Images.bg3,
        title: "Consulting",
        description:
            "Optimisez votre système d'information pour une performance et une sécurité accrues. Nos experts vous accompagnent dans l'élaboration d'une stratégie IT sur mesure, alignée sur vos objectifs métiers.",
    },
];

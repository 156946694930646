import React, { useCallback, useState } from 'react';
import { Images } from '../constant';
import { styles } from '../styles';
import { productOdooByCategories } from '../types';
import { ProductView } from './product_service_component';

const OdooService = () => {
    const [showAllCategories, setShowAllCategories] = useState<boolean>(false);

    const toggleCategories = useCallback(() => {
        setShowAllCategories(!showAllCategories);
    }, [showAllCategories]);

    // Limite initiale d'affichage à 4 catégories
    const categoriesToShow = showAllCategories
        ? productOdooByCategories
        : productOdooByCategories.slice(0, 4);

    return (
        <div className='w-full h-full'>
            <div className={`w-full h-full grid grid-cols-1 gap-y-16 overflow-hidden transition-all duration-500 md:duration-200 ease-in-out
                             ${showAllCategories ? 'max-h-full opacity-100 duration-500' : ''}`}>
                {categoriesToShow.map((value) => (
                    <div key={`product-${value.name}`} className="flex flex-col gap-8 md:gap-16 w-full h-full">
                        <h2 className='font-medium text-primary-700  space-x-4 md:space-x-8 font-semibold flex flex-row items-center' >
                            {/* <FontAwesomeIcon icon={e.icon} className='block text-4xl' /> */}
                            <span className={styles.serviceStyle["title-product"]}>{value.name}</span>

                        </h2>
                        <div className='w-full h-full grid grid-cols-1 md:gap-y-16 '>
                            {value.products.map((product, index) => (
                                <ProductView key={`odoo-${product.name}`} product={product} index={index} />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex  mt-8">
                <button
                    className=" flex flex-row items-center  text-gray-800 font-bold hover:text-white hover:bg-primary-300 px-8 py-4 rounded-lg shadow-md hover:bg-primary-600 focus:outline-none hover:shadow-xl transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110  duration-300"
                    onClick={toggleCategories}
                >
                    <span className='text-xl'>{showAllCategories ? 'Réduire' : 'Afficher toutes les apps'}</span>
                    <img src={Images.secondary_arrow_sm_03} width="40px" className="align-baseline ml-3 text-red-500" alt="" loading="lazy" />
                </button>
            </div>
        </div>
    );
}

export default OdooService;

import React from 'react';
import { glpiServices } from '../types';
import { ProductView } from './product_service_component';

const GlpiService = () => {


    const categoriesToShow = glpiServices

    return (
        <div className='w-full h-full '>
            <div className={`w-full h-full grid grid-cols-1 lg:gap-y-36 overflow-hidden transition-all duration-500 md:duration-200 ease-in-out
                           `}>
                {categoriesToShow.map((value, index) => (

                    <ProductView key={`glpi-${value.name}`} product={value} index={index + 1} />


                ))}
            </div>

        </div>
    );
}

export default GlpiService;

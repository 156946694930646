import React from 'react'
import { Images } from '../constant'

export const Logo: React.FC = () => {
    return (
        <div className='w-24 h-24   md:w-[120px] md:h-[80px]  xl:w-[150px] lg:h-[110px] flex items-center justify-center'>
            <img src={Images.logo} alt="logo" className='object-contain w-full' />
        </div>
    );
};


///SAAS PAAS CONSULTING
//NOTRE

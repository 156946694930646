"use client";
import { ReactNode } from "react";
import { motion } from "framer-motion";

interface MotionWrapProps {
  classNames: string;
  Component: ReactNode;
  whileInView?: Object;
}

const scaleVariants = {
  whileInView: {
    scale: [0.8, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const ImageMotion = ({
  classNames,
  Component,
  whileInView,
}: MotionWrapProps) => {
  const result: any =
    whileInView ?? scaleVariants.whileInView;
  return (
    <motion.div
      whileInView={result}
      transition={{ duration: 0.5, delayChildren: 0.5, ease: "easeInOut" }}
      className={`${classNames}`}
    >
      {Component}
    </motion.div>
  );
};

export default ImageMotion;

import React from "react";
import { useParams } from "react-router-dom";

import { GlpiDetailPage, OdooDetailPage } from "./products";

export const ProductDetail = () => {
  const { serviceSlug } = useParams<{ serviceSlug: string }>();

  if (serviceSlug?.includes("odoo")) {
    return (
      <div>
        <OdooDetailPage />
      </div>
    );
  }

  return (
    <div>
      <GlpiDetailPage />
    </div>
  );
};

// src/pages/Home.tsx
import React from "react";
import { Images } from "../constant";
import styles from "../styles/home.module.css";
import { motion } from "framer-motion";
import {
  FaqQuestions,
  Fonctionnement,
  ListEmployeComponent,
  OffersComponents,
  References,
} from "../components/home";
import MotionWrap from "../components/commons/motionWrapper";
import ImageMotion from "../components/commons/wrappers/imageMotion";
import { listProces } from "../types/constants/home/list_methode";
import { H1, H2 } from "../components/commons/h_component";
import { homeConstant } from "../types/constants/home/home_constant";
import { contactUs } from "../constant/constant";
const Home: React.FC = () => {
  return (
    <div className="flex flex-col items-center bg-white relative items-center lg:h-full">
      <div
        className={`${styles.hero_content}  container  w-full h-full px-4 xl:px-16 2xl:px-0  pt-8 sm:pt-0 md:pt-0 lg:pt-16 xl:pt-0 relative  flex  md:flex-row items-center lg:justify-between px-0 -py-0   mx-0   flex-col-reverse gap-x-8`}
      >
        <div className="w-full lg:w-1/2 h-full flex flex-col  gap-4 2xl:gap-16  lg:pr-16 xl:pr-0 2xl:pr-16 items-center justify-center">
          <motion.div
            whileInView={{ x: [-100, 0], opacity: [0, 1] }}
            transition={{ duration: 0.5 }}
          // className="app__header-info"
          >
            <H1 classNames={`${styles["h1__home"]} flex flex-row justify-center items-center`} Component={
              <>{" "}
                <strong className="font-semibold sm:w-1/2  lg:w-full sm:pr-8 xl:pr-8">
                  {homeConstant.TITLE}
                </strong>{" "}
                <div className="block hidden sm:block lg:hidden w-1/2 my-8  lg:h-full">
                  <img
                    src={Images.bg1}
                    loading="lazy"
                    alt="background"
                    className="object-fill rounded-lg w-full"
                  />
                </div></>}></H1>

          </motion.div>
          <div className="block sm:hidden w-full  my-8  lg:w-1/2 lg:h-full">
            <img
              src={Images.bg1}
              loading="lazy"
              alt="background"
              className="object-fill rounded-lg w-full md:h-full"
            />
          </div>
          <motion.div
            whileInView={{ x: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1 }}
          >
            <p className="leading-8 text-lg">
              {homeConstant.description}
            </p>
          </motion.div>

          <div className="w-full flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-2 sm:gap-8 md:gap-8 lg:gap-8 mt-8 gap-y-8">
            <motion.div
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 1.5 }}
              className="sm:w-1/2 md:w-full"
            >
              <button className=" w-full  xl:w-4/5 px-8 py-6 border-2  border-primary-300 bg-transparent hover:bg-primary-300 hover:text-white font-semibold  transition-all duration-200 rounded-full  h-8  flex items-center justify-center">
                {" "}
                Voir Nos Tarifs
              </button>
            </motion.div>
            <motion.div
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 1.5 }}
              className="sm:w-1/2 md:w-full "
            >
              <a href={contactUs} className=" w-full  px-8 2xl:px-16 py-6 border-2 shadow-xl  border-primary-300 hover:border-primary-700   bg-primary-300  hover:bg-primary-700 text-white font-semibold  transition-colors duration-200 rounded-full  h-8  flex items-center justify-center">
                {" "}
                Contacter nous
              </a>
            </motion.div>
          </div>
        </div>
        <div className=" w-full lg:w-1/2 hidden  lg:block max-w-[650px]  min-h-[500px] h-full flex items-center justify-center">
          <img
            src={Images.bg1}
            loading="lazy"
            alt="background"
            className="object-contain rounded-lg w-full"
          />
        </div>
      </div>


      <section className=" w-full my-24  xl:mt-28 md:mb-8 flex justify-center ">
        <div className="container  w-full  h-full flex flex-col px-4  xl:px-16 2xl:px-0 items-center  gap-24">
          <ImageMotion
            classNames=""
            Component={
              <H2 classNames="" Component={<p>Nos offres de service</p>} />
            }
          />

          <MotionWrap
            classNames="w-full h-full  "
            Component={
              <div className=" w-full h-full flex flex-row  items-center justify-between">
                <div className=" w-full grid grid-cols-1  sm:grid-cols-2 xl:grid-cols-3 gap-8 lg:gap-16 ">
                  {listProces.map((process) => (
                    <div key={process.title} className="w-full py-2  shadow-md border-gray-600 rounded-md ">
                      <div className="flex flex-col gap-8 pb-4">
                        <div className="w-full bg-gray-100 min-h-80 h-80 flex items-center justify-center">
                          <img
                            src={process.icon as string ?? ""}
                            loading="lazy"
                            alt={process.title}
                            className="object-contain h-full"
                          />
                        </div>
                        <div className="flex flex-col px-4 gap-4 pr-12">
                          <h3 className="font-semibold text-2xl md:text-4xl pr-8 leading-10">
                            {process.title}
                          </h3>
                          <p className="text-justify">{process.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            }
          />
        </div>
      </section>

      {/*Nos offres */}
      <OffersComponents />

      <Fonctionnement />


      {/*Notre equipes */}
      <MotionWrap
        classNames="h-full w-full pt-16 sm:pt-0"
        Component={<ListEmployeComponent />}
      />

      {/*FAQ */}
      <FaqQuestions />

      {/*Nos References */}
      <MotionWrap classNames="w-full h-full" Component={<References />} />
    </div>
  );
};

export default Home;

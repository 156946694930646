import React from 'react';
import SubscriptionForm from '../../components/subscription/odoo/subscribe_to_odoo_form';
import subscriptionStepper from '../../components/subscription/subscriptionStepper';


const steps = [
    { step: 1, label: 'Vérification' },
    { step: 2, label: 'Informations utilisateur' },
    { step: 3, label: 'Validation' },
];

const SubscriptionOdooPage: React.FC = () => {


    const SubscriptionWithStepper = subscriptionStepper(SubscriptionForm,);

    return <SubscriptionWithStepper steps={steps} isGlpiSubscription={false} />;

};

export default SubscriptionOdooPage;

import { faCloud, faServer, faCogs, faLock, faNetworkWired } from '@fortawesome/free-solid-svg-icons';

export interface ProductService {
  name: string;
  description: string;
  icon: any; // Replace 'any' with the correct type for FontAwesome icons
  color:  string;
      
}

export interface ProductCategorie {
  title: string;
  icon: any; // Replace 'any' with the correct type for FontAwesome icons
  products: ProductService[];
}

export const services: ProductCategorie[] = [
  {
    title: 'Offres SAAS',
    icon: faCloud,
    products: [
      { 
        name: "CloudApps Odoo", 
        description: "Odoo est une suite d'applications de gestion d'entreprise qui inclut des modules pour la comptabilité, la gestion des stocks, la gestion de projet et bien plus, le tout dans une solution intégrée.",
        icon: faCloud,
        color: "#06768d", // Light blue
         
      },
      { 
        name: "CloudApps GLPI", 
        description: "GLPI est un outil de gestion des actifs informatiques et un système de suivi des problèmes. Il aide les entreprises à organiser et à gérer leur infrastructure informatique de manière efficace et centralisée.",
        icon: faCloud,
        color: "#ffc107" //"#046276", // Sky blue
          
      }
    ]
  },
  {
    title: "Offre Paas",
    icon: faServer,
    products: [
      { 
        name: "Jenkins Server as Service", 
        description: "Jenkins est un serveur d'automatisation open-source qui facilite l'intégration continue et le déploiement continu de vos projets logiciels, permettant une livraison rapide et fiable des applications.",
        icon: faServer,
        color: " #6f42c1" //"#03254c", // Royal blue
         
      },
      { 
        name: "AWX Server As Service", 
        description: "AWX est un outil de gestion d'automatisation open-source qui permet de gérer et d'automatiser les tâches IT. Il est conçu pour simplifier les déploiements et la gestion de configurations.",
        icon: faServer,
        color:  "#007bff"//"#336286", // Light blue
          
      },
      { 
        name: "GitLab Servers As Service", 
        description: "GitLab est une plateforme DevOps complète qui fournit des outils pour la gestion de code source, l'intégration continue, et le déploiement continu, tout en facilitant la collaboration entre les équipes.",
        icon: faServer,
        color: 
          "#6610f2" //"#3c649f", // Sky blue
         
      },
     
    ]
  },
  {
    title: "Consulting",
    icon: faNetworkWired,
    products: [
      { 
        name: "Cloud/Architecture Design", 
        description: "Nous concevons et mettons en œuvre des architectures cloud robustes et évolutives qui répondent aux besoins spécifiques de votre entreprise, en garantissant performance, sécurité et coût-efficacité.",
        icon: faNetworkWired,
        color: "#17a2b8"//"#4779c4", // Light blue
          
      },
      { 
        name: "Cybersecurity", 
        description: "Nos services de cybersécurité protègent vos systèmes connectés à Internet contre les cybermenaces, en assurant la confidentialité, l'intégrité et la disponibilité de vos données et infrastructures critiques.",
        icon: faLock,
        color: "#b5a2f8"//"#3c649f", // Sky blue
          
      }, { 
        name: "DevOps / DevSecOps", 
        description: "Les pratiques DevOps et DevSecOps intègrent le développement, les opérations et la sécurité pour améliorer l'efficacité des processus de développement logiciel et garantir la sécurité dès le début du cycle de vie.",
        icon: faCogs,
        color:  "#007bff"//"#4169E1", // Royal blue
         
      },
    ]
  }
];
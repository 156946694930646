import clsx from "clsx";
import React, { useState, FC, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Images } from "../../constant";

import {
    calculateAssetsInstallationCost,
    calculateAssetsInstallationDays,
    calculateInstallationCost,
    calculateMaintenanceCost,
    calculateMiseOeuvre,
    calculateNumberDays,
    calculatePlanCost,
} from "../../constant/functions";
import { RootState } from "../../store";
import StepperComponent from "../home/stepper/stepper";
import ResumeComponent from "./resume_component";
import FormSubscription from "./form_subscription";
import { FormDataState, initialFormData } from "../../types/model/form_data_model";

interface Step {
    step: number;
    label: string;
}

interface WithStepperProps {
    steps: Step[];
    isGlpiSubscription: boolean;
}

const subscriptionStepper = (ComponentOne: FC<any>) => {
    const WrappedComponent: FC<WithStepperProps> = ({
        steps,
        isGlpiSubscription,
    }) => {
        const [currentStep, setCurrentStep] = useState(0);
        const [formData, setFormData] = useState<FormDataState>(initialFormData);

        const handleNextStep = (data: any) => {
            setFormData((prevData) => ({ ...prevData, ...data }));
            setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
        };


        const handlePreviousStep = () => {
            setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
        };

        const handleResetForm = (e: any) => {
            setFormData(initialFormData);
        };

        const handleSetCurrent = (value: number) => {
            setCurrentStep(value)
        }

        return (
            <div className={clsx("flex flex-col items-center relative items-center lg:h-full pt-14 pb-24",
                { "bg-white": currentStep === 2 })}>
                <div className=" w-full container mx-auto px-4 xl:px-16 2xl:px-0">
                    {currentStep !== 0 && (
                        <StepperComponent
                            steps={steps.map((step, index) => ({
                                label: step.label,
                                step: index + 1,
                            }))}
                            goStep={handleSetCurrent}
                            activeStep={currentStep + 1}
                        />
                    )}
                    {currentStep === 0 && (
                        <PhaseOneForm
                            onNext={handleNextStep}
                            Component={ComponentOne}
                        />
                    )}
                    {currentStep === 1 && (
                        <PhaseTwoForm
                            formData={formData}
                            onReset={handleResetForm}
                            isGlpiForm={isGlpiSubscription}
                            setFormData={setFormData}
                            onNext={handleNextStep}
                            onPrevious={handlePreviousStep}
                        />
                    )}
                    {currentStep === 2 && (
                        <PhaseThreeForm
                            onPrevious={handlePreviousStep}
                            formCommand={formData}
                            setFormData={setFormData}
                            isGlpiPage={isGlpiSubscription}
                        />
                    )}
                </div>
            </div>
        );
    };

    return WrappedComponent;
};

export default subscriptionStepper;

interface PhaseOneFormProps {
    onNext: ({ city }: { city: string }) => void;
    Component: FC<any>;
}

const PhaseOneForm: FC<PhaseOneFormProps> = ({ onNext, Component }) => {
    return <Component onNext={onNext} />;
};

interface PhaseTwoFormProps {
    onPrevious: () => void;
    onNext: (data: any) => void;
    onReset: (e: any) => void;
    formData: FormDataState;
    isGlpiForm: boolean;

    setFormData: React.Dispatch<React.SetStateAction<FormDataState>>;
}

const PhaseTwoForm: FC<PhaseTwoFormProps> = ({
    onNext,
    onPrevious,
    isGlpiForm,
    onReset,
    formData,
    setFormData,
}) => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        },
        [setFormData]
    );

    return (
        <div className="w-full h-full py-14 pb-24">
            <div className="w-full h-full mx-auto flex flex-col gap-8">
                <h2 className="text-4xl font-semibold mb-4">Adresse de facturation</h2>
                <div className="w-full flex flex-col lg:flex-row">
                    <div className="w-full  lg:px-0 xl:w-3/5 flex items-center justify-center">
                        <div className="relative bg-white shadow-lg w-full h-full">
                            <div className={clsx("relative w-full mx-auto")}>
                                <div className="w-full container mx-auto p-4  flex flex-col items-center justify-center gap-10 py-8">
                                    <FormSubscription
                                        onPrevious={onPrevious}
                                        formData={formData}
                                        onChange={handleChange}
                                        onReset={onReset}
                                        onNext={onNext}
                                        isGlpiForm={isGlpiForm}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface PhaseThreeFormProps {
    onPrevious: () => void;
    isGlpiPage: boolean;
    formCommand: FormDataState;
    setFormData: React.Dispatch<React.SetStateAction<FormDataState>>;

}

const PhaseThreeForm: FC<PhaseThreeFormProps> = ({
    onPrevious,
    isGlpiPage,
    formCommand,
    setFormData
}) => {


    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                methodOfPayment: value,
            }));
        },
        [setFormData]
    );

    const { company, lastName, firstName, postalCode, address, country, city, methodOfPayment } =
        formCommand;

    const glpiSubscription = useSelector((state: RootState) => state.glpiSubscription);
    const odooSubscription = useSelector((state: RootState) => state.odooSubscription);

    const subscriptionSelector = useMemo(() => {
        return isGlpiPage ? glpiSubscription : odooSubscription;
    }, [isGlpiPage, glpiSubscription, odooSubscription]);

    const numberOfItems = useMemo(() => {
        return isGlpiPage ? glpiSubscription.numberOfAssets : odooSubscription.numberOfEmployees;
    }, [isGlpiPage, glpiSubscription, odooSubscription]);

    const website = useMemo(() => {
        return isGlpiPage ? false : odooSubscription.website;
    }, [isGlpiPage, odooSubscription]);

    return (
        <div className="w-full flex flex-col md:flex-row gap-8 lg:gap-16 min-h-screen h-full py-8">
            <div className="w-full md:w-1/2 lg:w-7/12  2xl:w-2/3 h-full flex flex-col gap-6">
                <h2 className="text-4xl font-semibold">Adresse</h2>

                <div className="w-full flex flex-col gap-4">
                    <h3 className="text-2xl font-medium">Facturation</h3>

                    <div className="border border-gray-300 rounded-lg w-3/4 md:w-fit p-8 bg-gray-100 flex flex-col w-full gap-4">
                        <span className="font-medium text-wrap text-lg break-words capitalize">
                            {company}, {firstName}, {lastName}
                        </span>

                        <div className="w-full flex flex-col text-wrap text-sm break-words capitalize">
                            <span>{address}</span>
                            <span>
                                {postalCode} {city}
                            </span>

                            <span>{country}</span>
                        </div>

                        <div className="flex items-center w-full justify-start">
                            <button
                                onClick={onPrevious}
                                title="Modifier cette adresse"
                                className="text-sm font-bold text-primary-500 flex flex-row items-center justify-center gap-1"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="size-4"
                                >
                                    <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                                </svg>

                                <span>Modifier</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-3/4 flex flex-col gap-4 mt-8">
                    <h4 className="uppercase font-bold">
                        Choisissez un mode de paiement
                    </h4>
                    <div className="w-full border border-gray-400 rounded-lg ">
                        <div className={clsx("p-4 flex w-full flex-col gap-4", { "border-2 rounded-t-md border-sky-600": methodOfPayment === "creditCard" })}>
                            <div className="w-full flex justify-between gap-4">
                                <div className="inline-flex items-center  whitespace-nowrap">
                                    <input
                                        type="radio"
                                        name="methodOfPayment"
                                        onChange={handleChange}
                                        id="creditCard"
                                        value="creditCard"
                                        checked={methodOfPayment === "creditCard"}
                                    />
                                    <label htmlFor="creditCard" className="ml-2 font-medium">
                                        Carte de Credit
                                    </label>
                                </div>
                                <div>
                                    <div className=" grid grid-cols-2 lg:grid-cols-4 gap-1">
                                        <span
                                            className="relative block rounded overflow-hidden z-1 shadow-sm"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-delay="0"
                                            title=""
                                            data-bs-original-title="American Express"
                                            aria-label="American Express"
                                        >
                                            <img
                                                src={Images.americanExpress}
                                                className="img img-fluid"
                                                alt="American Express"
                                                loading="lazy"
                                            />
                                        </span>
                                        <span
                                            className="relative block rounded overflow-hidden z-1 shadow-sm"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-delay="0"
                                            title=""
                                            data-bs-original-title="Maestro"
                                            aria-label="Maestro"
                                        >
                                            <img
                                                src={Images.maestro}
                                                className="img img-fluid"
                                                alt="Maestro"
                                                loading="lazy"
                                            />
                                        </span>
                                        <span
                                            className="relative block rounded overflow-hidden z-1 shadow-sm"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-delay="0"
                                            title=""
                                            data-bs-original-title="MasterCard"
                                            aria-label="MasterCard"
                                        >
                                            <img
                                                src={Images.masterCard}
                                                className="img img-fluid"
                                                alt="MasterCard"
                                                loading="lazy"
                                            />
                                        </span>
                                        <span
                                            className="relative block rounded overflow-hidden z-1 shadow-sm"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-delay="0"
                                            title=""
                                            data-bs-original-title="VISA"
                                            aria-label="VISA"
                                        >
                                            <img
                                                src={Images.visa}
                                                className="img img-fluid"
                                                alt="VISA"
                                                loading="lazy"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={clsx({ 'hidden': methodOfPayment !== "creditCard" })}>
                            </div>
                        </div>
                        <div className={clsx("p-4 flex w-full flex-col gap-4 border-t-2", { "border-2 rounded-b-md border-sky-600": methodOfPayment === "mobileMoney" })}>
                            <div className="w-full flex justify-between ">
                                <div className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        onChange={handleChange}
                                        name="methodOfPayment"
                                        id="mobileMoney"
                                        value="mobileMoney"
                                        checked={methodOfPayment === "mobileMoney"}
                                    />
                                    <label htmlFor="mobileMoney" className="ml-2 font-medium">
                                        Mobile Money
                                    </label>
                                </div>

                                <div className="grid grid-cols-2  gap-1">
                                    <span
                                        className="relative block rounded overflow-hidden z-1 shadow-sm"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-delay="0"
                                        title=""
                                        data-bs-original-title="Orange Money"
                                        aria-label="Orange Money"
                                    >
                                        <img
                                            src={Images.orange}
                                            className="w-12"
                                            alt="Orange Money"
                                            loading="lazy"
                                        />
                                    </span>
                                    <span
                                        className="relative block rounded overflow-hidden z-1 shadow-sm"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-delay="0"
                                        title=""
                                        data-bs-original-title="MTN MONEY"
                                        aria-label="MTN MONEY"
                                    >
                                        <img
                                            src={Images.mtn}
                                            className="w-12"
                                            alt="MTN MONEY"
                                            loading="lazy"
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className={clsx({ 'hidden': methodOfPayment !== "mobileMoney" })}>
                                <p className="text-justify text-sm  pl-6"> Veuillez noter que <i>des frais de traitement</i> supplémentaires équivalant à environ <b>5 %</b> du montant total seront facturés afin de couvrir les frais de transaction par l'operateur.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-5/12  2xl:w-1/3  rounded rounded-[0.625rem] border-[1px] border-gray-300 h-full">
                <ResumeComponent
                    subscriptionSelector={subscriptionSelector} numberOfItems={numberOfItems} calculateNumberDays={isGlpiPage ? calculateAssetsInstallationDays : calculateNumberDays}
                    calculateInstallationCost={isGlpiPage ? calculateAssetsInstallationCost : calculateInstallationCost}
                    calculateMaintenanceCost={calculateMaintenanceCost}
                    calculateMiseOeuvre={calculateMiseOeuvre}
                    calculatePlanCost={calculatePlanCost}
                    isGlpi={isGlpiPage}
                    website={website}
                    methodOfPayment={methodOfPayment}
                    onSubmit={(e) => console.log(formCommand)}
                />
            </div>
        </div>
    );
};



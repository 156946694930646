import clsx from "clsx";
import { styles } from "../styles";
import { ProductService, services } from "../types/constants/products/service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import { createSlug } from "../utils/createSlug";
import { H2 } from "../components/commons/h_component";


const Products = () => {
    return (
        <section className="w-full bg-white h-full flex flex-col items-center justify-center gap-24">
            <div className={clsx(styles.productStyle['page-title-area'], "min-h-64 h-64 md:h-96 w-full bg-gray-100 flex items-center justify-center")}>
                <h3 className="text-4xl lg:text-6xl text-white  font-semibold">Produits</h3>
            </div>
            <div className="mb-24  container w-full h-full px-4 xl:px-16 2xl:px-0 ">
                <div className="mx-auto text-center mb-16">

                    <H2 classNames={clsx(styles.productStyle["title"])} Component={
                        <>  Solutions cloud pour tous vos besoins</>
                    } />
                </div>
                <div className=" mt-24 w-full h-full grid grid-cols-1   gap-y-24">

                    {services.map((e) => (
                        <div
                            key={`product-${e.title}`}
                            className="w-full h-full flex flex-col gap-14">


                            <motion.div
                                whileInView={{ opacity: [0, 1] }}
                                transition={{ duration: 1, delayChildren: 0.5 }}


                            >
                                <h4 className='font-medium text-primary-700 text-2xl lg:text-4xl space-x-4 md:space-x-8 font-semibold flex flex-row items-center' >
                                    <FontAwesomeIcon icon={e.icon} className='block text-4xl' />
                                    <span className="uppercase">{e.title}</span>
                                </h4>  </motion.div>
                            <div className=" w-full h-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4  gap-x-8 gap-y-8">
                                {e.products.map(product => (
                                    <motion.div
                                        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5 }}
                                        key={`pr-${product.name}`}

                                    >
                                        <ProductView product={product} />
                                    </motion.div>

                                ))}

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Products;

interface SubRouteProps {
    product: ProductService;
}



const ProductView: React.FC<SubRouteProps> = ({ product }) => {
    const [isHovered, setIsHovered] = useState(false);

    const iconColor = isHovered ? 'white' : product.color;

    return (
        <button


            className={clsx(styles.productStyle['group'],
                'group w-full h-full text-center px-8 py-6 relative z-1 rounded-md mb-30 shadow-none border-[2px] border-dotted transition-all duration-500 hover:border-none hover:shadow-xl'
                , 'gap-4',
                'flex flex-col items-center justify-between'
                , {
                    'text-white': isHovered
                }
            )}
            style={{ transition: "background-color 0.5s", backgroundColor: isHovered ? product.color : 'transparent', borderColor: isHovered ? 'transparent' : product.color }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onFocus={() => setIsHovered(true)}
            onBlur={() => setIsHovered(false)}

        >
            <div
                className={clsx(styles.productStyle['icon'], 'border-[1.5px] border-dashed border-gray-800')}
                style={{ color: isHovered ? 'white' : product.color }}
            >
                <FontAwesomeIcon icon={product.icon} color={iconColor} />
            </div>
            <p className="text-xl font-semibold  mt-1 transition transition-all duration-200">{product.name}</p>
            <p className="text-base font-base transition transition-all duration-500">{product.description}</p>

            <Link
                to={`/products/${createSlug(product.name)}`}
                className={clsx(styles.productStyle['default-btn-one'], 'group-hover:border-white hover:border-none mt-2 w-full py-6 border-2 border-primary-300 bg-transparent hover:bg-white hover:text-gray-800 font-semibold transition-colors duration-200 rounded-full h-8 flex items-center justify-center')}
            >
                Voir plus
            </Link>
        </button>
    );
};
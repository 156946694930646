import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Products from './pages/Products';
import Blog from './pages/Blogs';
import SignIn from './pages/SignIn';
import { ProductDetail } from './pages/product_detail';
import { SubscriptionGlpiPage } from './pages/subscription/subscrition_glpi';
import SubscriptionOdooPage from './pages/subscription/subscribe_odoo';
import Cart from './pages/cart';


const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:serviceSlug" element={<ProductDetail />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/signin" element={<SignIn />} />

      <Route path="/cart" element={<Cart />} />
      <Route path='/subcribe-glpi' element={<SubscriptionGlpiPage />}></Route>
      <Route path='/subcribe-odoo' element={<SubscriptionOdooPage />}></Route>

    </Routes>
  );
}

export default AppRoutes;

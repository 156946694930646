import clsx from "clsx";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { contactUs } from "../constant/constant";
import { RouteLink, routes } from "../types/model/route";
import { createSlug } from "../utils/createSlug";
import { Logo } from "./logo";



const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/product') {
      setIsOpen(false);
    }
  }, [location]);

  const [isDropdown1Open, setIsDropdown1Open] = useState(false);
  const [isDropdown2Open, setIsDropdown2Open] = useState(false);

  const toggleDropdown = useCallback(
    () => {
      setIsDropdown1Open(prev => !prev);
      setIsDropdown2Open(false)
    },
    [],
  )

  const toggleDropdown2 = useCallback(() => {
    setIsDropdown2Open(prev => !prev);
    setIsDropdown1Open(false)
  }, [],
  )

  useEffect(() => {
    if (location.pathname !== '/product') {
      setIsDropdown1Open(false);
    }
    setIsDropdown2Open(false)
  }, [location]);

  return (
    <nav className="bg-white fixed sm:sticky transition-all duration-500 md:fixed w-full z-10 top-0">
      <div className="container mx-auto px-4  xl:px-16 2xl:px-0 flex justify-between items-center">
        <div className="flex-shrink-0">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="flex flex-row gap-24 items-center justify-center">
          <div className="hidden lg:flex space-x-8 text-gray-800">
            <ul className="flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white ">
              {routes.map((route) =>
                route.subLink ? (
                  !route.isPricing ? <SubRoute key={route.name} handleChange={toggleDropdown} isDropdownOpen={isDropdown1Open} route={route} />
                    :
                    <SubPricingRoute key={route.name} handleChange={toggleDropdown2} isDropdownOpen={isDropdown2Open} route={route} />
                ) : (
                  <li
                    key={route.name}
                    className="relative group py-2 px-3   md:p-0  "
                  >
                    <Link
                      to={route.path}
                      className="font-bold group-hover:text-primary-300 group-hover:font-semibold"
                    >
                      {route.name}
                    </Link>
                    <div className="block h-0.5  bg-primary-300 absolute left-0 -bottom-2 w-full scale-x-0 group-hover:scale-x-100 transition-transform origin-bottom-right group-hover:origin-bottom-left"></div>
                  </li>
                )
              )}
            </ul>
          </div>
          {/* <div className="hidden md:block w-8"></div> */}
          <div className="hidden lg:flex space-x-8 ">
            <a href={contactUs} className="px-8 py-2 border-2  border-primary-300 bg-transparent hover:bg-primary-300 hover:text-white font-semibold  transition-all duration-200 rounded-full flex items-center justify-center">
              {" "}
              Contactez-nous
            </a>

            <Link
              to="/signin"
              className="bg-primary-300 text-white px-8 py-2 rounded-full hover:bg-gray-600 font-bold flex items-center justify-center"
            >
              Connexion
            </Link>


          </div>
        </div>
        <div className="lg:hidden ">
          <button
            onClick={toggleMenu}
            className="text-gray-800 hover:text-gray-900 focus:outline-none"
          >
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div
        className={clsx(
          "container mx-auto px-4  xl:px-16 2xl:px-0 lg:hidden flex flex-col justify-between inset-0  transform  overflow-y-auto  transition-all duration-500 md:duration-200 ease-in-out",
          {
            "max-h-0 opacity-0": !isOpen,
            "h-screen pb-8 max-h-[95vh] opacity-100": isOpen,
          }
        )}
      >
        <ul className="text-lg   lg:text-3xl flex md:py-8 flex-col  md:gap-4 py-4 lg:p-0 lg:space-x-8 rtl:space-x-reverse lg:flex-row md:mt-0 md:border-0 md:bg-white ">
          {routes.map((route) =>
            route.subLink ? (
              !route.isPricing ? <SubRoute key={route.name} handleChange={toggleDropdown} isDropdownOpen={isDropdown1Open} route={route} />
                :
                <SubPricingRoute key={route.name} handleChange={toggleDropdown2} isDropdownOpen={isDropdown2Open} route={route} />
            ) : (
              <li
                key={route.name}
                className="relative group py-2 px-3 md:p-0"
              >
                <Link
                  to={route.path}
                  className="font-bold group-hover:text-primary-300 group-hover:font-semibold"
                >
                  {route.name}
                </Link>
                <div className="block h-0.5 bg-primary-300 absolute md:left-0 bottom-0 md:-bottom-2 w-8 lg:w-full scale-x-0 group-hover:scale-x-100 transition-transform origin-bottom-right group-hover:origin-bottom-left"></div>
              </li>
            )
          )}
        </ul>

        <div className="w-full  flex flex-col gap-4 pt-4">
          <a href={contactUs} className="px-8 py-2 border-2  border-primary-300 bg-transparent hover:bg-primary-300 hover:text-white font-semibold  transition-all duration-200 rounded-lg flex items-center justify-center">
            {" "}
            Contactez-nous
          </a>

          <Link
            to="/signin"
            className="bg-primary-300 text-white px-8 py-2 rounded-lg hover:bg-gray-600 font-bold flex items-center justify-center"
          >
            Connexion
          </Link>


        </div>
      </div>
    </nav>
  );
};

interface SubRouteProps {
  route: RouteLink;
  isDropdownOpen: boolean
  handleChange: (e: any) => void
}

const SubRoute: React.FC<SubRouteProps> = memo(
  ({ route, isDropdownOpen, handleChange }) => {

    return (
      <li className=" ">
        <div
          // to={'/products'}

          className="group flex items-center  w-full py-2 px-3  md:w-auto  md:border-0 md:hover:text-primary-700 md:p-0 "
        >
          <Link to={'/products'} className="font-bold group-hover:text-primary-300 group-hover:font-semibold">
            {" "}
            {route.name}
          </Link>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            onClick={handleChange}
            stroke="currentColor"
            className={`ml-2 h-5 w-5 font-bold group-hover:text-primary-300 group-hover:font-semibold  transition-transform duration-500 md:duration-200 ${isDropdownOpen ? "rotate-180" : ""
              }`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>

        <div
          className={clsx(
            "lg:absolute left-0 right-0 lg:mt-8 border-gray-200 lg:shadow-sm  lg:bg-white lg:border-t  transform overflow-hidden transition-all duration-500 md:duration-200 ease-in-out",
            {
              "max-h-0 opacity-0": !isDropdownOpen,
              "max-h-[1000px] opacity-100": isDropdownOpen
            })
          }>
          <div className="grid md:min-w-screen md:w-screen px-4 py-2 lg:py-10 mx-auto text-gray-900  sm:grid-cols-2 xl:sm:grid-cols-3 gap-8 lg:gap-24 px-8 lg:px-24 2xl:px-48">
            {route.subLink?.map(sub => (
              <div key={sub.title} className="flex flex-col ">
                <h3 className="font-semibold text-md lg:text-2xl xl:text-4xl uppercase lg:text-center">{sub.title}</h3>
                <div className="mt-2 mb-6 h-[0.5px] lg:h-[2px] w-full bg-primary-500"></div>
                <div className="w-full flex flex-col gap-1 md:gap-4">
                  {sub.routes?.map(subR => (
                    <div key={subR.name} className="px-4">
                      <li
                        key={route.name}
                        className="relative group py-2 px-3   md:p-0  "
                      >
                        <Link
                          to={'products/' + createSlug(subR.name)}
                          className="capitalize group-hover:text-primary-300 group-hover:font-semibold"
                        >
                          {" "}
                          {subR.name}
                        </Link>
                        <div className="block h-0.5  bg-primary-300 absolute left-0 -bottom-2 w-16 scale-x-0 group-hover:scale-x-100 transition-transform origin-bottom-right group-hover:origin-bottom-left"></div>
                      </li></div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </li>
    );
  }
)



const SubPricingRoute: React.FC<SubRouteProps> = memo(({ route, isDropdownOpen, handleChange }) => {

  return (
    <li className=" ">
      <button
        onClick={handleChange}

        className="group flex items-center  w-full py-2 px-3  md:w-auto  md:border-0 md:hover:text-primary-700 md:p-0 "
      >
        <span className="font-bold group-hover:text-primary-300 group-hover:font-semibold">
          {" "}
          {route.name}
        </span>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          // onClick={toggleDropdown}
          stroke="currentColor"
          className={`ml-2 h-5 w-5 font-bold group-hover:text-primary-300 group-hover:font-semibold  transition-transform duration-500 md:duration-200 ${isDropdownOpen ? "rotate-180" : ""
            }`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>

      <div
        className={clsx(
          "lg:absolute left-0 right-0 lg:mt-8 border-gray-200 lg:shadow-sm  lg:bg-white lg:border-t  transform overflow-hidden transition-all duration-500 md:duration-200 ease-in-out",
          {
            "max-h-0 opacity-0": !isDropdownOpen,
            "max-h-[400px] opacity-100": isDropdownOpen
          })
        }>
        <div className="grid md:min-w-screen md:w-screen px-4 py-2 lg:py-10 mx-auto text-gray-900  sm:grid-cols-2 xl:sm:grid-cols-3 gap-8 lg:gap-24 px-8 lg:px-24 2xl:px-48">
          {route.subLink?.map(sub => (
            <div key={sub.title} className="flex flex-col ">
              <h3 className="font-semibold text-md lg:text-2xl xl:text-4xl uppercase lg:text-center">{sub.title}</h3>
              <div className="mt-2 mb-6 h-[0.5px] lg:h-[2px] w-full bg-primary-500"></div>
              <div className="w-full flex flex-col gap-1 md:gap-4">
                {sub.routes?.map(subR => (
                  <div key={subR.name} className="px-4">
                    <li
                      key={route.name}
                      className="relative group py-2 px-3   md:p-0  "
                    >
                      <Link
                        to={subR.path}
                        className="capitalize group-hover:text-primary-300 group-hover:font-semibold"
                      >
                        {" "}
                        {subR.name}
                      </Link>
                      <div className="block h-0.5  bg-primary-300 absolute left-0 -bottom-2 w-16 scale-x-0 group-hover:scale-x-100 transition-transform origin-bottom-right group-hover:origin-bottom-left"></div>
                    </li></div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </li>
  );
})
export default Navbar;

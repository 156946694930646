import clsx from "clsx";
import React, { useState, useCallback, useMemo } from "react";
import {
    dailyCost,
    initialLocation,
    monthlySubscription,
    websitePrice,
} from "../../constant/constant";
import { calculateCostDepla } from "../../constant/functions";
import { FormBaseState } from "../../types/model/state_form_subscribe";


const CostItem = ({
    label,
    cost,
    isAnnual,
}: {
    label: string;
    cost: number;
    isAnnual: boolean
}) => (
    <div className="w-full pt-4 flex flex-row gap-4 items-center justify-between">
        <span className="w-fit capitalize">{label}</span>
        <span className="inline-flex flex-col items-end whitespace-nowrap">
            <span className="font-medium">
                {cost} <span className="font-normal">XFA</span>
            </span>
            <span>par {isAnnual ? 'an' : 'mois'}</span>
        </span>
    </div>
);


interface ResumeProps {
    subscriptionSelector: FormBaseState;
    calculateNumberDays: any,
    calculateInstallationCost: any,
    calculateMiseOeuvre: any,
    calculatePlanCost: any,
    calculateMaintenanceCost: any,
    numberOfItems: number,
    methodOfPayment: string;
    isGlpi: boolean;
    website: boolean;
    onSubmit: (e: any) => void

}

const ResumeComponent = ({
    subscriptionSelector,
    calculateNumberDays,
    calculateInstallationCost,
    calculateMiseOeuvre,
    calculatePlanCost,
    numberOfItems,
    methodOfPayment,
    calculateMaintenanceCost,
    isGlpi,
    website,
    onSubmit
}: ResumeProps) => {
    const [toggle, setToggle] = useState(false);

    const handleToggle = useCallback(() => {
        setToggle((prev) => !prev);
    }, []);

    const {
        hostingType,
        typePlan,
        isAnnual,
        selectedProducts,
        numUsers,
        maintenance,
        location,
        totalPrice,
        typeMaintenance,
        supportImplementation,

    } = subscriptionSelector;

    const nDays = useMemo(
        () => calculateNumberDays(numberOfItems),
        [numberOfItems, calculateNumberDays]
    );

    const installationCost = useMemo(
        () => {
            if (hostingType === 'cloud') {
                return 0;
            }
            return calculateInstallationCost(
                calculateCostDepla(location ?? initialLocation, nDays),
                dailyCost,
                nDays
            );
        },

        [hostingType, location, nDays, calculateInstallationCost]
    );

    const miseOeuvreCost = useMemo(
        () => calculateMiseOeuvre(selectedProducts),
        [selectedProducts, calculateMiseOeuvre]
    );

    const planCost = useMemo(
        () => {
            if (hostingType !== 'cloud' || totalPrice === 0) {
                return 0;
            }
            let cost = calculatePlanCost(typePlan);
            if (isAnnual) {
                return cost * monthlySubscription
            } return cost
        }, [totalPrice, typePlan, hostingType, isAnnual, calculatePlanCost]
    );

    const maintenanceCost = useMemo(
        () => {
            let cost = calculateMaintenanceCost(typeMaintenance)

            if (isAnnual) {
                return cost * monthlySubscription
            }
            return cost
        },
        [typeMaintenance, isAnnual, calculateMaintenanceCost]
    );

    return (
        <div className="px-4 md:px-8 py-5 md:py-10 w-full h-full flex flex-col gap-6   md:text-lg">
            <div className="w-full flex flex-row justify-between">
                <p className="font-bold">Résumé de la commande</p>
                <button>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        onClick={handleToggle}
                        stroke="currentColor"
                        className={`size-7 font-bold group-hover:text-primary-300 group-hover:font-semibold transition-transform duration-500 ${toggle ? 'rotate-180' : ''
                            }`}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                    </svg>
                </button>
            </div>

            <div id="articles"
                className={clsx(`overflow-hidden transition-all duration-500 ease-in-out   w-full divide-y-[1px] divide-gray-300 flex flex-col gap-6 items-center justify-center`,
                    {
                        'max-h-[800px]': toggle,
                        'max-h-0': !toggle
                    })}>
                {hostingType === 'cloud' && (
                    <CostItem label={`Plan ${typePlan}`} cost={planCost} isAnnual={isAnnual} />
                )}
                <CostItem
                    label={`Instance ${hostingType} (${numUsers} ${numUsers > 1 ? 'utilisateurs' : 'utilisateur'
                        })`}
                    cost={planCost}
                    isAnnual={isAnnual}
                />
                {maintenance && (
                    <CostItem label="Support de maintenance" cost={maintenanceCost} isAnnual={isAnnual} />
                )}
                {supportImplementation && (
                    <CostItem label="Mise en oeuvre" cost={miseOeuvreCost} isAnnual={isAnnual} />
                )}
                {hostingType !== 'cloud' && (
                    <CostItem label="Forfait d'installation" cost={installationCost} isAnnual={isAnnual} />
                )}
                {website && (
                    <CostItem label="Site Web" cost={websitePrice} isAnnual={isAnnual} />
                )}
            </div>
            <div id="cart_total" className="w-full pt-4 border-t-[1px] border-gray-300">
                <table className="table mb-0 w-full">
                    <tbody>
                        <tr id="order_total_untaxed">
                            <td
                                id="cart_total_subtotal"
                                className="border-0 pb-2 text-start text-muted"
                                colSpan={2}
                            >
                                Sous-total
                            </td>
                            <td className="text-end border-0 pb-2">
                                <span className="monetary_field" style={{ whiteSpace: 'nowrap' }}>
                                    <span className="oe_currency_value">{totalPrice}</span>&nbsp;XFA
                                </span>
                            </td>
                        </tr>
                        <tr id="order_total_taxes">
                            <td colSpan={2} className="text-muted pt-0 pb-4">
                                Taxes
                            </td>
                            <td className="text-end pe-0 pt-0 pb-4">
                                <span className="monetary_field" style={{ whiteSpace: 'nowrap' }}>
                                    <span className="oe_currency_value">0,00</span>&nbsp;XFA
                                </span>
                            </td>
                        </tr>
                        <tr id="order_total" className="border-t-[1px] border-gray-300">
                            <td colSpan={2} className="pt-4">
                                <strong>Total</strong>
                            </td>
                            <td className="text-end border-0 px-0 pt-4">
                                <strong className="monetary_field text-end p-0">
                                    <span className="oe_currency_value">{totalPrice}</span>&nbsp;XFA
                                </strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="pt-4 flex flex-col gap-8">
                <div className="block w-full flex flex-col gap-6">
                    <h4 className="font-bold text-xl">Conditions de ventes</h4>
                    <div>
                        <span className="uppercase">Ventes Finales</span> - Veuillez noter que
                        toutes les ventes d'abonnements Cloudapps Enterprise et des services
                        connexes sont définitives et non remboursables.
                    </div>
                </div>
                <div className="w-full">
                    <button
                        disabled={methodOfPayment === ''}
                        onClick={onSubmit}
                        className={clsx(
                            "h-14 w-full flex items-center justify-center text-center bg-primary-500 sm:py-2 transition ease-in duration-300  text-white font-bold rounded",
                            {
                                "hover:ease-out hover:bg-blue-500 hover:md:shadow-lg opacity-100 focus:shadow-outline focus:outline-none": methodOfPayment !== ''
                            }
                            , {
                                "opacity-80": methodOfPayment === ''
                            }
                        )}>
                        Confirmer
                        <svg
                            className="ml-1 size-4 -rotate-90 stroke-2 stroke-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m19.5 8.25-7.5 7.5-7.5-7.5"
                            />
                        </svg>
                    </button>
                    <div className="w-full flex flex-row gap-x-4 py-4">
                        <div className="flex-grow flex items-center">
                            <div className="w-full bg-gray-300 h-[1px]"></div>
                        </div>
                        <span>ou</span>
                        <div className="flex-grow flex items-center">
                            <div className="w-full bg-gray-300 h-[1px]"></div>
                        </div>
                    </div>
                    <a href="/cart" className="h-14 w-full flex items-center justify-center text-center text-gray-900 bg-transparent border border-gray-300 sm:py-2 transition ease-in duration-300 hover:ease-out hover:bg-gray-200 focus:shadow-outline focus:outline-none font-bold rounded">

                        <svg
                            className="mr-1 size-4 stroke-gray-900 rotate-90 stroke-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m19.5 8.25-7.5 7.5-7.5-7.5"
                            />
                        </svg>
                        Retour au panier
                    </a>
                </div>
            </div>
        </div>
    );
};


export default ResumeComponent;
